import React from 'react'
import { Link } from 'react-router-dom'
import rightArrow from "../../assets/images/RightArrow.png"

import classes from "./Products.module.css"

export default function Products() {
    return (
        <div className='container'>
            <h2 className={classes.productTitle}>Products of Healthport</h2>
            <div className={`${classes.card__container} grid__container grid__coloumn_6`}>
                <div className={classes.product}>
                    <img src={rightArrow} alt="right arrow" />
                    <p>International Medical Treatment</p>
                </div>
                <div className={classes.product}>
                    <img src={rightArrow} alt="right arrow" />
                    <p>Doctors’ Second Opinion</p>
                </div>
                <Link to="/diagonostics" style={{textDecoration: "none"}}>
                    <div className={classes.product}>
                        <img src={rightArrow} alt="right arrow" />
                        <p>Diagnostic Test</p>
                    </div>
                </Link>
                <div className={classes.product}>
                    <img src={rightArrow} alt="right arrow" />
                    <p>Financial Card for Healthcare Expenditures</p>
                </div>
            </div>
        </div>
    )
}
