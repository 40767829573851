import React, { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'

import classes from "./Login.module.css"

export default function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const {error, Login} = useLogin()

    const handleSubmit = (e) => {
        e.preventDefault()

        Login(email, password)
    }
    return (
        <form onSubmit={handleSubmit} className={classes.login_form}>
        <h2>login</h2>
        <label>
            <span>Email:</span>
            <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
        </label>
        <label>
            <span>Password:</span>
            <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
        </label>
        {
            error && <p style={{color: "red"}}>{error}</p>
        }
        <button className='btn__blue'>Login</button>
    </form>
    )
}
