import { useContext } from "react"
import { DetailContext } from "../context/DetailContext"

export const useDetailContext = () => {
    const context = useContext(DetailContext)

    if(!context) {
        throw new Error("useDetailContext must be inside an DetailContextProvider")
    }

    return context
}