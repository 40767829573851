import React from 'react'
import DashboardFormAdd from '../../../components/organisms/DashboardFormAdd/DashboardFormAdd'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'
import DashboardTable from '../../../components/organisms/DashboardTable/DashboardTable'


import classes from "./DashboardSymptoms.module.css"

export default function DashboardSymptoms() {
    return (
        <div>
            
            <div className={classes.telemedicine}>
                <DashboardSidebar />

                <div className={classes.telemedicine__form}>
                    <DashboardFormAdd path="/symptoms/add" title="Symptoms Form List" />
                    <div className={classes.telemedicine__table}>
                        <DashboardTable data ={ SymptomsData} />
                    </div>
                </div>
            </div>
        </div>
    )
}


export const SymptomsData = [
    {
        id: 1,
        date: "28/10/2020",
        email: "stevesmith@gmail.com",
        status: "pending"
    },
    {
        id: 2,
        date: "28/04/2021",
        email: "stevesmith@gmail.com",
        status: "done"
    },
    {
        id: 3,
        date: "28/04/2021",
        email: "stevesmith@gmail.com",
        status: "pending"
    },
    {
        id: 5,
        date: "28/04/2021",
        email: "stevesmith@gmail.com",
        status: "done"
    }
]