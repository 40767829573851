import React, { useState } from 'react'
import classes from "./MainNavigation.module.css"
import { NavLink } from 'react-router-dom'

import menuOpen from "../assets/images/menuOpen.png"
import closeMenu from "../assets/images/closeMenu.png"
import { useLogin } from '../hooks/useLogin'
import { useAuthContext } from '../hooks/useAuthContext'

export default function MainNavigation() {
    const [showNavigation, setShowNavigation] = useState(true)
    const { user } = useAuthContext()
    console.log(user)
    return (
        <>
            {
                showNavigation ? <img onClick={() => setShowNavigation(false)} className={classes.menuOpen} src={menuOpen} alt="menu" /> : <img onClick={() => setShowNavigation(true)} className={classes.menuOpen} style={{ zIndex: "10" }} src={closeMenu} alt="menu" />

            }


            <div className={`${classes.main__navigation} ${showNavigation ? classes.none : ""} `}  >
                <ul className={`${classes.main__lists}`} onClick={() => setShowNavigation(true)}>
                    <NavLink to="/" activeClassName="active" style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>Home</li>
                    </NavLink>
                    <NavLink to="/about-us" activeClassName="active" style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>About Us</li>
                    </NavLink>
                    <NavLink to="/contact-us" activeClassName="active" style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>Contact Us</li>
                    </NavLink>
                    <NavLink to="/products" activeClassName="active" style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>Products</li>
                    </NavLink>
                    <NavLink to="/hospitals" activeClassName="active"  style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>Hospitals</li>
                    </NavLink>
                    <NavLink to="/patient-journey" activeClassName="active" style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>Patient Journey</li>
                    </NavLink>
                    {/* <NavLink to="/treatments" activeClassName="active" style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>Treatments</li>
                    </NavLink> */}
                    {/* <NavLink to="/doctors" activeClassName="active" style={{ textDecoration: "none" }}>
                        <li className={classes.main__list}>Doctors</li>
                    </NavLink> */}
                    <NavLink to="/diagonostics" activeClassName="active" style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>Diagnostics</li>
                    </NavLink>
                    {/* <NavLink to="/financial-card" activeClassName="active" style={{ textDecoration: "none" }}>
                        <li className={classes.main__list}>Financial Card</li>
                    </NavLink> */}
                    <NavLink to="/tele-medicine" activeClassName="active" style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>Tele Medicine</li>
                    </NavLink>
                    {/* <NavLink to="/vaccine" activeClassName="active" style={{ textDecoration: "none", color: "#111B56" }}>
                        <li className={classes.main__list}>Vaccine</li>
                    </NavLink> */}
                </ul>
            </div>
        </>
    )
}
