import React, { useState } from 'react'
import { useFireStore } from '../../../hooks/useFireStore'
import classes from "./PartnerForm.module.css"

export default function PartnerForm() {
    let [company, setCompany] = useState("")
    let [person, setPerson] = useState("")
    let [phone, setPhone] = useState("")
    let [secondayPhone, setSecondayPhone] = useState("")
    let [email, setEmail] = useState("")
    let [address, setAddress] = useState("")
    let [companyType, setCompanyType] = useState("")

    const { addDocument, response } = useFireStore("became_a_partner")

    const handleSubmit = e => {
        e.preventDefault()
        const partnerData = {
            companyName: company,
            contactPerson: person,
            phoneNumber: phone,
            secondaryPhone: secondayPhone,
            email: email,
            address: address,
            companyType: companyType
        }
        if (company && person && phone && secondayPhone && email && address && companyType) {
            addDocument(partnerData)

            setCompany("")
            setPerson("")
            setPhone("")
            setSecondayPhone("")
            setEmail("")
            setAddress("")
            setCompanyType("")
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <label className={classes.input__container}>
                <span className={classes.label}>Name of Company</span>
                <input className={classes.input} value={company} onChange={e => setCompany(e.target.value)} type="text" />
            </label>
            <label className={classes.input__container}>
                <span className={classes.label}>Name of Contact person</span>
                <input className={classes.input} value={person} onChange={e => setPerson(e.target.value)} type="text" />
            </label>
            <label className={classes.input__container}>
                <span className={classes.label}>Phone Number</span>
                <input className={classes.input} value={phone} onChange={e => setPhone(e.target.value)} type="number" />
            </label>
            <label className={classes.input__container}>
                <span className={classes.label}>Secondary Phone Number</span>
                <input className={classes.input} value={secondayPhone} onChange={e => setSecondayPhone(e.target.value)} type="number" />
            </label>
            <label className={classes.input__container}>
                <span className={classes.label}>Email Address</span>
                <input className={classes.input} value={email} onChange={e => setEmail(e.target.value)} type="email" required />
            </label>
            <label className={classes.input__container}>
                <span className={classes.label}>Office Address</span>
                <input className={classes.input} value={address} onChange={e => setAddress(e.target.value)} type="address" />
            </label>
            <label className={classes.input__container}>
                <span className={classes.label}>Company Type</span>
                <select className="selected" value={companyType} onChange={e => setCompanyType(e.target.value)} name="company" id="company">
                    <option value="Travel Agent">Travel Agent</option>
                    <option value="Corporate">Corporate</option>
                    <option value="Pharmacies">Pharmacies</option>
                    <option value="Others">Others</option>
                </select>
            </label>
            <button className='btn__blue' onClick="submit">Submit</button>
            {
                response.success && <p className='success'>Thanks for filling out our form!</p>
            }
        </form>
    )
}
