import React from 'react'
import { useCollection } from "../../../hooks/useCollection"
import DashboardFormTitle from '../../../components/organisms/DashboardFormTitle/DashboardFormTitle'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'


import classes from "./DashboardFormlist.module.css"
import FormTable from './FormTable/FormTable'

export default function DashboardFormlist() {
    const {document: allList} = useCollection("all__list")
    const formData = allList.filter((data => data?.doc?.dataFrom === "form" ||  data?.doc?.dataFrom === "home"))
    console.log(formData)

    return (
        <div>
            
            <div className={classes.formlist__container}>
                <DashboardSidebar />
                <div className={classes.formlist__form}>
                    <DashboardFormTitle title="Forms" />
                    <div className={classes.formlist__listTable}>
                        <FormTable data={formData} />
                    </div>
                </div>
            </div>
        </div>
    )
}
