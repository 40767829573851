import React from 'react'
import pj from './../../assets/images/hp_patient_journey.png'
import loadingSpinner from "../../assets/images/loadingSpinner.gif"

export default function PatientJourney() {
    return (
        <>
            {
                pj ? <div className='container'>
                    <img style={{ width: "100%", height: "100%" }} src={pj} />
                </div> : <div className="loadingImg">
                    <img src={loadingSpinner} />
                </div>
            }
        </>
    )
}
