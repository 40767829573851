import { useEffect, useState } from "react"
import { collection, onSnapshot } from 'firebase/firestore'
import { db } from '../firebase.config'

export const useCollection = (collectionName) => {
    const [document, setDocument] = useState([])
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let ref = collection(db, collectionName)
        setIsLoading(true)
        
        const unSubscribe = onSnapshot(ref, (snapshot) => {
            let result = []
            snapshot.docs.forEach(doc => {
                result.push({...doc.data(), id: doc.id})
            })
            setDocument(result)
            setIsLoading(false)
        })

        return () => unSubscribe()
    }, [collectionName])
    
    return { document , isLoading}
}