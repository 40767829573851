import { useState } from 'react'
import { useFireStore } from '../../hooks/useFireStore'
import { useVaccineContext } from '../../hooks/userVaccineContext'
import classes from "./VaccineCheckout.module.css"

export default function VaccineCheckout() {
    let [isSubmitted, setIsSubmitted] = useState(false)
    const [email, setEmail] = useState(null)
    const [number, setNumber] = useState("")
    const [name, setName] = useState('')
    const [age, setAge] = useState("")
    const [gender, setGender] = useState("")
    const [address, setAddress] = useState("")
    const [living, setLiving] = useState("")
    const [payment, setPayment] = useState("")

    const { items, nullItems, totalAmount } = useVaccineContext()

    const { addDocument, response } = useFireStore("order_Vaccine")

    const reset = () => {
        setName("")
        setEmail("")
        setNumber("")
        setAge("")
        setGender("")
        setAddress("")
        setLiving("")
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const selectedData = {
            name,
            email,
            number,
            age,
            gender,
            address,
            living,
            paymentMethod: payment,
            items,
            totalAmount
        }
        if (name == "" || age == "" || email == "" || gender == "" || number == "" || address == "" || living == "" || payment == "") {
            alert("please, fill all the field")
            return
        }
        if (name !== "" && age !== "" && email !== "" && number !== "" && gender !== "" && address !== "" && living !== "" && payment !== "") {
            addDocument(selectedData)
            nullItems()
            reset()
            setIsSubmitted(true)
        }
    }
    return (
        <div>
            <div className={classes.diagonstics__Checkout}>
                <form onSubmit={handleSubmit}>
                    <label className={classes.input__container}>
                        <span className={classes.label}>Name *</span>
                        <input className={classes.input} value={name} onChange={e => setName(e.target.value)} type="text" />
                    </label>
                    <label className={classes.input__container}>
                        <span className={classes.label}>Date of Birth *</span>
                        <input type="date" className={classes.input} value={age} onChange={e => setAge(e.target.value)} />
                    </label>
                    <label className={classes.input__container}>
                        <span className={classes.label}>Gender *</span>
                        <select className='select_input' onChange={e => setGender(e.target.value)} name="cars" id="cars">
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </label>
                    <label className={classes.input__container}>
                        <span className={classes.label}>Phone Number *</span>
                        <input className={classes.input} value={number} onChange={e => setNumber(e.target.value)} type="tel" pattern="[0-9]{11}" required />
                        <p style={{fontSize: "1.6rem"}}>Format Should be: 01*********</p>
                    </label>
                    <label className={classes.input__container}>
                        <span className={classes.label}>Email Address *</span>
                        <input className={classes.input} value={email} onChange={e => setEmail(e.target.value)} type="email" />
                    </label>
                    <label className={classes.input__container}>
                        <span className={classes.label}>Address *</span>
                        <input className={classes.input} value={address} onChange={e => setAddress(e.target.value)} type="text" />
                    </label>
                    <label className={classes.input__container}>
                        <span className={classes.label}>Living/Work Area *</span>
                        <input className={classes.input} value={living} onChange={e => setLiving(e.target.value)} type="text" />
                    </label>
                    <div>
                        <p className={classes.radio__title}>Choose your preffered payment method: *</p>

                        <div className={classes.radio__container}>
                            <input type="radio" id="Cash" value="Cash" onChange={e => setPayment(e.target.value)} name="select" />
                            <label for="Cash">Cash On Delivery</label>
                        </div>
                    </div>
                    <div className={classes.selected__item}>
                        <h2>Selected Exam:</h2>
                        {
                            items.map(item => <div className={classes.selected__name}>
                                <p>{item.Name}</p>
                                <p>{item.amount}</p>
                                <p>Price : {item.Price}</p>
                            </div>)
                        }
                        <p style={{ textAlign: "right", marginRight: "2.2rem", fontWeight: "bold" }}>Total Price : {totalAmount}</p>
                    </div>

                    <div className='text_center mt_2'>
                        <button className='btn__blue pd_4'>Submit</button>
                    </div>
                </form>
                {
                    isSubmitted && <p style={{textAlign: "center", fontSize: "1.6rem"}}>Thanks for filling out our form!</p>
                }
            </div>
        </div>
    )
}

