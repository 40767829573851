import React from 'react'
import editIcon from '../../../../assets/images/edit.png'
import deleteIcon from '../../../../assets/images/delete.png'

import { deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../../../firebase.config'

export default function FormTable({data}) {
    const handleDelete = async(id) => {
        console.log(id)
        const ref = doc(db, "all__list", id)
        await deleteDoc(ref)
    }
    return (
        <div className='tableScrool'>
        <table>
            <thead className="table__heading">
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th>Address</th>
                    <th>Living Area</th>
                    <th>Time of Call</th>
                    <th>Selected Organs</th>
                    <th>Selected Symptoms</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody className="table__body">
                {
                    data && data.map((item, i) => (
                        <tr>
                            <td>{item?.doc?.name}</td>
                            <td>{item?.doc?.email}</td>
                            <td>{item?.doc?.number}</td>
                            <td>{item?.doc?.gender}</td>
                            <td>{item?.doc?.age}</td>
                            <td>{item?.doc?.address}</td>
                            <td>{item?.doc?.livingArea}</td>
                            <td>{item?.doc?.preferredTimeToCall}</td>
                            <td>{item?.doc?.selectedOrgans}</td>
                            <td>{item?.doc?.selectedSymptoms}</td>
                            <td>
                                <div>
                                    {/* <img style={{ width: "30px", height: "30px" }} src={editIcon} alt="wrong icon" /> */}
                                    <img style={{ width: "30px", height: "30px" }} onClick={() => handleDelete(item.id)} src={deleteIcon} alt="delete icon" />
                                </div>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        </div>
    )
}
