import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/molecules/Button/Button'
import { useFireStore } from '../../../hooks/useFireStore'

import classes from "./DiagonosticsFormAdd.module.css"

export default function DiagonosticsFormAdd({ btnTitle }) {
    const navigate = useNavigate()
    const [description, setDescription] = useState(0)
    const [title, setTitle] = useState("")
    const [price, setPrice] = useState(0)
    const [category, setCategory] = useState("")
    const { addDocument } = useFireStore("diagonstics")

    const handleSubmit = e => {
        e.preventDefault()

        const data = {
            ExamName: title,
            price: Number(price),
            description: description,
            category: category
        }

        if (data.description && data.ExamName && data.price && data.category) {
            addDocument(data)
            navigate('/admin/diagonostics')
        }
    }
    return (
        <div className={classes.dashboardForm}>
            <h2 className={classes.dashboardTitle}>{btnTitle}</h2>
            <form onSubmit={handleSubmit} className={classes.form}>
                <div className={`${classes.contact_form} grid__container grid__coloumn_6`}>
                    <div className={`${classes.input}`}>
                        <input type='text' placeholder='Diagonostics Name' onChange={e => setTitle(e.target.value)} />
                    </div>
                    <div className={`${classes.input}`}>
                        <input type='number' placeholder='Diagonostics Price' onChange={e => setPrice(e.target.value)} />
                    </div>
                </div>
                <div className={`${classes.contact_form} grid__container grid__coloumn_6`}>
                    <div className={`${classes.input}`}>
                        <textarea type='text' placeholder='Diagonostics Description' onChange={e => setDescription(e.target.value)} />
                    </div>
                    <div className={`${classes.input}`}>
                        <div className='filter'>
                            <select className='selected' name="cars" onChange={e => setCategory(e.target.value)} id="cars">
                                <option value="all">ALL Category</option>
                                <option value="Tissue Test">Tissue Test</option>
                                <option value="Blood Test">Blood Test</option>
                                <option value="Imaging">Imaging</option>
                                <option value="Stool">Stool</option>
                                <option value="Sputum Test">Sputum Test</option>
                                <option value="Urine Test">Urine Test</option>
                                <option value="Body Fluid Test">Body Fluid Test</option>
                                <option value="Swab Test">Swab Test</option>
                            </select>
                        </div>
                    </div>
                </div>
                <Button className="flex">{btnTitle}</Button>
            </form>
        </div>
    )
}
