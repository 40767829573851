import React from 'react'
import classes from "./SelectedItems.module.css"
import deleteIcon from '../../../assets/images/delete.png'

export default function SelectedItems({ item, removeItem }) {
    const { Price, Category, Name, id , amount} = item
    const handleRemove = (id) => {
        removeItem(id)
    }
    return (
        <tr className={classes.row}>
            <td>{Name}</td>
            <td>{Price}</td>
            <td>{amount}</td>
            <td>{Category}</td>
            <td onClick={() => handleRemove(id)}><img style={{width: "30px", height:"30px"}} src={deleteIcon} alt="delete" /></td>
        </tr>
    )
}
