import { ref, uploadBytesResumable ,getDownloadURL } from 'firebase/storage';
import { useState, useEffect } from 'react';
import { projectStorage } from '../firebase.config';

export const useStorage = (file) => {
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const storageRef = ref(projectStorage, `files/${file?.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const prog = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(prog);
            },
            (err) => setError(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setUrl(downloadURL)
                });
            }
        );
    }, [file])

    return { url, error, progress }
}