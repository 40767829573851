import React, { useState, useEffect } from 'react'
import { db } from "../../firebase.config";
import { collection, getDocs } from "firebase/firestore";
import VaccineDetails from './components/VaccineDetails'

import classes from "./Vaccine.module.css"
import { useNavigate } from 'react-router-dom';
import { useDetailContext } from '../../hooks/useDetailContext';

export default function Vaccine() {
    let [selectedBody, setSelectedBody] = useState("body of adult")
    let [vaccine, setVaccine] = useState([])
    let [selectedSymptoms, setSelectedSymptoms] = useState("")
    const navigate = useNavigate("")
    const {detail, dispatch} = useDetailContext()

    let vaccineCollectionRef = collection(db, "vaccine__for")

    useEffect(() => {
        const getBody = async () => {
            const data = await getDocs(vaccineCollectionRef)
            setVaccine(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }

        getBody()
    }, [])

    const handleVaccineDetails = () => {
        const selectedDetails = {
            category: selectedBody,
            selectSymptoms: selectedSymptoms,
            selectOrgans: "",
            dataFrom: "vaccine"
        }


        dispatch({type: "VACCINE_DETAIL", payload: selectedDetails})
        navigate("/checkout")
    }

    return (
        <div className={classes.vaccine__page}>
            <div className="container">
                <VaccineDetails setSelectedBody={setSelectedBody} vaccine={vaccine} selectedBody={selectedBody} selectedSymptoms={selectedSymptoms} setSelectedSymptoms={setSelectedSymptoms} />
                {
                    selectedBody && selectedSymptoms && <div className={classes.btn__container}>
                    <button className={classes.btn} onClick={handleVaccineDetails} >Continue</button>
                </div>
                }
            </div>
        </div>
    )
}
