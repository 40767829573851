import { auth } from '../firebase.config'
import {signOut} from "@firebase/auth"
import { useAuthContext } from './useAuthContext'
import { useNavigate } from "react-router-dom"

export const useLogout = () => {
    const {dispatch} = useAuthContext()
    const navigate = useNavigate("")

    const LogOut = () => {
        signOut(auth)
        .then(()=> {
            dispatch({type: "LOGOUT"})
            navigate("/login")
        })
        .catch(err => {
            console.log(err.message)
        })
    }

    return {LogOut}
}