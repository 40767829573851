import React from 'react'

import classes from "./IndividualDiagonostics.module.css"
import { useDiagonstics } from '../../../../hooks/useDiagonstics'

export default function IndividualDiagonostics({ data }) {
    const { Name, Price, id, Description, Category } = data
    const { addItem } = useDiagonstics()

    const handleDiagonstics = () => {
        addItem({ ...data, amount: 1, id: id })
    }

    return (
        <div className={classes.diagonostics__card}>
            <h2 className={classes.diagonostics__name}>{Name}</h2>
            <p className={classes.diagonostics__price}>Price :<span className={classes.price}> {Price}</span> BDT</p>
            <p className={classes.diagonostics__desc}>{Description}</p>
            <p className={classes.diagonostics__desc}>Category: {Category}</p>
            <button className={classes.diagonostics__btn} onClick={handleDiagonstics} >Add To Cart</button>
        </div>
    )
}
