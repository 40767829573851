import React, { useContext } from 'react'
import { FaSyringe, FaRegUser, FaHandshake, FaWpforms, FaBriefcaseMedical, FaDiagnoses, FaHospitalSymbol, FaDeezer, FaBorderNone, FaHospitalUser, FaChalkboardTeacher } from "react-icons/fa";

import classes from "./DashboardSidebar.module.css"

import { Link } from 'react-router-dom'
import { useLogout } from "../../../hooks/useLogout"

export default function DashboardSidebar() {
    const { LogOut } = useLogout()
    return (
        <div className={classes.sidebar}>
            <div className={classes.sidebar__container}>

                <a href="https://analytics.google.com/analytics/web/?authuser=0#/p294381906/reports/dashboard?r=firebase-overview" target="_blank" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaDeezer />
                        <p>Analytics</p>
                    </div>
                </a>
                <Link to="/admin/form-list" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaWpforms />
                        <p>Forms</p>
                    </div>
                </Link>

                <Link to="/admin/telemedicine" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaBriefcaseMedical />
                        <p>Telemedicine</p>
                    </div>
                </Link>
                <Link to="/admin/vaccine" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaSyringe />
                        <p>Vaccine</p>
                    </div>
                </Link>

                <Link to="/admin/hospital-list" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaHospitalSymbol />
                        <p>Hospitals</p>
                    </div>
                </Link>


                {/* <Link to="/admin/symptom-list" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaChalkboardTeacher />
                        <p>Symptoms</p>
                    </div>
                </Link> */}
                {/* <Link to="/admin/treatments" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaHospitalUser />
                        <p>Treatments</p>
                    </div>
                </Link> */}
                <Link to="/admin/diagonostics" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaDiagnoses />
                        <p>Diagonostics</p>
                    </div>
                </Link>
                {/* <Link to="/admin/diagonostics-2" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaDiagnoses />
                        <p>Diagonostics 2</p>
                    </div>
                </Link> */}
                <Link to="/admin/partner" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaHandshake />
                        <p>Became Partner</p>
                    </div>
                </Link>
                <Link to="/admin/diagonostics-order" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaBorderNone />
                        <p>Diagonostics Order</p>
                    </div>
                </Link>
                <Link to="/admin/vaccine-order" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item}>
                        <FaBorderNone />
                        <p>Covid 19 Request</p>
                    </div>
                </Link>
                <div class="dropdown">
                    <button class="dropbtn">Add</button>
                    <div class="dropdown-content">
                        <Link to="/admin/hospital-list/add">Add Hospital</Link>
                        <Link to="/admin/diagonostics/add">Add Diagonostics</Link>
                        <Link to="/admin/symptoms/add">Add Symptoms</Link>
                    </div>
                </div>
                <Link to="" style={{ textDecoration: "none", color: "#fff" }}>
                    <div className={classes.sidebar__item} onClick={LogOut}>
                        <FaRegUser />
                        <p>Logout</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}
