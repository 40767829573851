import { logEvent } from 'firebase/analytics';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { analytics } from '../../../../firebase.config';
import { useCollection } from '../../../../hooks/useCollection';
import classes from "../../Hospitals.module.css"

export default function HospitalDetails() {
    const { id } = useParams();

    const { document: hospitalData } = useCollection("hospitals")
    const individualData = hospitalData.filter(data => data.id === id)

    useEffect(() => {
        logEvent(analytics, 'from Hospital page');
    }, [])
    return (
        <div className={`${classes.hospital_details} container`}>
            <img src={individualData[0]?.data?.hospitalImg} alt={individualData[0]?.data?.hospitalName} />
            <h6>{individualData[0]?.data?.hospitalName}</h6>

            <div >
                <p>{individualData[0]?.data?.hospitalDescription}</p>
            </div>
        </div>
    )
}
