import { createContext, useReducer } from "react";

const initialState = {
    items: [],
    totalAmount: 0
}

export const VaccineContext = createContext({
    items: [],
    totalAmount: 0,
    addItem: (item) => { },
    removeItem: (id) => { }
})

const vaccineReducer = (state, action) => {

    if (action.type === 'ADD') {
        const updatedTotalAmount =
            state.totalAmount + Number(action.item.Price) * Number(action.item.amount);

        const existingCartItemIndex = state.items.findIndex(
            (item) => item.id === action.item.id
        );
        const existingCartItem = state.items[existingCartItemIndex];
        let updatedItems;

        if (existingCartItem) {
            const updatedItem = {
                ...existingCartItem,
                amount: existingCartItem.amount + action.item.amount,
            };
            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
        } else {
            updatedItems = state.items.concat(action.item);
        }

        return {
            items: updatedItems,
            totalAmount: updatedTotalAmount,
        };
    }



    if (action.type === 'REMOVE') {
        const existingCartItemIndex = state.items.findIndex(
            (item) => item.id === action.id
        );
        const existingItem = state.items[existingCartItemIndex];
        const updatedTotalAmount = Number(state.totalAmount) - Number(existingItem.Price);
        let updatedItems;
        if (existingItem.amount === 1) {
            updatedItems = state.items.filter(item => item.id !== action.id);
        } else {
            const updatedItem = { ...existingItem, amount: Number(existingItem.amount) - 1 };
            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
        }

        return {
            items: updatedItems,
            totalAmount: updatedTotalAmount
        };
    }


    if (action.type === 'NULL') {
        return {
            items: [],
            totalAmount: 0
        }
    }

    return initialState
}


export const VaccineProvider = ({ children }) => {
    const [vaccine, dispatchCartState] = useReducer(vaccineReducer, initialState)
    console.log("vaccine Details", vaccine)

    
    const AddItemToCartHandler = (item) => {
        dispatchCartState({ type: "ADD", item: item })
    }

    const removeItemToCartHandler = (id) => {
        dispatchCartState({ type: "REMOVE", id: id })
    }

    const nullItems = () => {
        dispatchCartState({type: "NULL"})
    }

    const cartDetails = {
        items: vaccine.items,
        totalAmount: vaccine.totalAmount,
        addItem: AddItemToCartHandler,
        removeItem: removeItemToCartHandler,
        nullItems: nullItems
    }
    return (
        <VaccineContext.Provider value={cartDetails}>
            {children}
        </VaccineContext.Provider>
    )
}
