import React, { useEffect } from 'react'
import classes from './Home.module.css'

// import { Main, Sidebar } from './components'
import Details from './components/Details'
// import { adultBodySegment, womenBodySegment, babyBodySegment } from './data'

export default function Home({ category, setCategory, selectOrgans, setSelectOrgans, organs, setOrgans, adultBodySegment, womenBodySegment, babyBodySegment, symptoms }) {
    
    return (
        <div className={classes.homepage}>
            <Details category={category} setCategory={setCategory} organs={organs} setOrgans={setOrgans} selectOrgans={selectOrgans} setSelectOrgans={setSelectOrgans} adultBodySegment={adultBodySegment} womenBodySegment={womenBodySegment} babyBodySegment={babyBodySegment} symptoms={symptoms} />
        </div>
    )
}
