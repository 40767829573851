import React, { useState } from 'react'

import classes from "./DiagonosticsTable.module.css"
import editIcon from '../../../assets/images/edit.png'
import deleteIcon from '../../../assets/images/delete.png'
import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase.config'
import { useFireStore } from '../../../hooks/useFireStore'

export default function DiagonosticsTable({ data }) {

    let [defaultName, setDefaultName] = useState("")
    let [defaultPrice, setDefaultPrice] = useState("")
    let [updateId, setUpdateId] = useState("")
    let [show, setShow] = useState(false)


    const handleDiagonsticsUpdate = (id, name, price) => {
        setUpdateId(id);
        setDefaultName(name)
        setDefaultPrice(price)
        setShow(true)
    }


    const { updateDocument, response } = useFireStore("diagnostics");
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");

    const handleDelete = async (id) => {
        const ref = doc(db, "diagonstics", id);
        await deleteDoc(ref);
    }

    const handleUpdate = async (e) => {
        e.preventDefault()

        const obj = {
            "Name": name ? name : defaultName,
            "Price": price ? price : defaultPrice,
        }
        updateDocument(updateId, obj)
        setName("")
        setPrice("")
        setDefaultName("")
        setDefaultPrice("")
        setShow(false)
    }

    return (
        <>
            {
                show && <form>
                    <label>
                        <span className={classes.label}>Diagnostics Name</span>
                        <input className={classes.input} defaultValue={defaultName} onChange={e => setName(e.target.value)} />
                    </label>
                    <label>
                        <span className={classes.label}>Price</span>
                        <input className={classes.input} defaultValue={defaultPrice} type="number" onChange={e => setPrice(e.target.value)} />
                    </label>
                    <button className={`${classes.btn} mb_2`} onClick={handleUpdate} >UPDATE</button>
                </form>
            }

            {
                response.isLoading && <div className='update_progress'>Updating Diagnostics</div>
            }
            {
                response.success && <div className='update_success'>Diagnostics updated succesfully</div>
            }

            <div className='tableScrool'>
                <table>
                    <thead className={classes.table__heading}>
                        <tr>
                            <th>Exam Name</th>
                            <th>Price</th>
                            <th>category</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody className={classes.table__body}>

                        {
                            data && data.map((item, i) => (
                                <tr key={item.id}>
                                    <td>{item?.Name}</td>
                                    <td>{item?.Price}</td>
                                    <td>{item?.Category}</td>
                                    <td>
                                        <div>
                                            <img style={{ width: "30px", height: "30px" }} src={editIcon} onClick={() => handleDiagonsticsUpdate(item.id, item.Name, item.Price)} alt="edit icon" />
                                            <img style={{ width: "30px", height: "30px" }} onClick={() => handleDelete(item.id)} src={deleteIcon} alt="delete icon" />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
