import React from 'react'
import DashboardFormTitle from '../../../components/organisms/DashboardFormTitle/DashboardFormTitle'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'
import DashboardTable from '../../../components/organisms/DashboardTable/DashboardTable'
import { useCollection } from '../../../hooks/useCollection'


import classes from "./VaccineListPage.module.css"
import VaccineTable from './VaccineTable/VaccineTable'

export default function VaccineListPage() {
    const {document: allList} = useCollection("all__list")
    const vaccineData = allList.filter(data => data?.doc?.dataFrom === "vaccine")
    return (
        <div>
            
            <div className={classes.vaccine__container}>
                <DashboardSidebar />
                <div className={classes.vaccine__form}>
                    <DashboardFormTitle title="Vaccine List Form" />
                    <div className={classes.vaccine__listTable}>

                        <VaccineTable data = {vaccineData} />
                    </div>
                </div>
            </div>
        </div>
    )
}



export const vaccineData = [
    {
        id: 1,
        date: "28/10/2020",
        email: "stevesmith@gmail.com",
        status: "done"
    },
    {
        id: 2,
        date: "28/04/2021",
        email: "stevesmith@gmail.com",
        status: "done"
    },
    {
        id: 3,
        date: "12/11/2020",
        email: "stevesmith@gmail.com",
        status: "pending"
    },
    {
        id: 4,
        date: "18/10/2020",
        email: "stevesmith@gmail.com",
        status: "done"
    },
    {
        id: 5,
        date: "18/10/2020",
        email: "stevesmith@gmail.com",
        status: "done"
    },
    {
        id: 6,
        date: "18/10/2020",
        email: "stevesmith@gmail.com",
        status: "pending"
    },
    {
        id: 7,
        date: "18/10/2020",
        email: "stevesmith@gmail.com",
        status: "pending"
    },
]