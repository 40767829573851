import React from 'react'

import classes from "./DashboardFormTitle.module.css"
import searchIcon from '../../../assets/images/searchIcon.png'
import filterIcon from '../../../assets/images/filterIcon.png'

export default function DashboardFormTitle({ title }) {
    return (
        <div className={classes.title__container}>
            <h4 className={classes.title}>{title}</h4>
            <div className={classes.input__container}>
                {/* <div className={classes.input}>
                    <img  className={classes.input__img} src={searchIcon} alt="search Icon" />
                    <input className={classes.input__field} type="text" placeholder="Search" />
                </div>
                <div className={classes.input}>
                    <img  className={classes.input__img} src={filterIcon} alt="filter Icon" />
                    <input className={classes.input__field} type="text" placeholder="Filters" />
                </div> */}
            </div>
        </div>
    )
}
