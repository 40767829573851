import React from 'react'
import aboutImg from './../../../../assets/images/about2.png'
import classes from './PartnerShip.module.css'

export default function PartnerShip() {
    return (
        <div className='grid__container grid__coloumn_6'>
            <div className={`${classes.about_partnership}`}>
                {/* <span>Find out</span> */}
                <h3>Our Partnerships</h3>
                <p style={{fontSize: "1.6rem"}}>Healthport has partnered with high quality, modern, technically well-equipped and highly acclaimed hospitals with well-reputed specialized doctors across Asia and Europe. It qualifies its partner hospitals based on their good will, expertise, treatment and recovery time and process including costs corresponding to particular diseases and treatment.
                    To make Healthport services accessible to the patients, family members of the patients and health service providers, it has established a wide network through social media, call centers and agents in various locations. In addition, Healthport executives are happy to join our clients at the comfort of their house to discuss health tourism strategy that suites them. Healthport also has a value chain network with local physicians, travel agents, pharmacists, diagnostic centers, hospitals and other health service providers.
                </p>
            </div>
            <div style={{textAlign: "end"}}>
            <img src={aboutImg} alt='HealthPort Bangladesh' />
            </div>
        </div>
    )
}
