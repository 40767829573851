import React from 'react'

import editIcon from '../../../assets/images/edit.png'
import deleteIcon from '../../../assets/images/delete.png'

import { deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../../firebase.config'

export default function DiagonosticsOrderedTable({data}) {
    const handleDelete = async(id) => {
        console.log(id)
        const ref = doc(db, "order_Vaccine", id)
        await deleteDoc(ref)
    }
    return (
        <div className='tableScrool'>
        <table>
            <thead className="table__heading">
                <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th>email</th>
                    <th>Gender</th>
                    <th>Date of Birth</th>
                    <th>Living Area</th>
                    <th>Diagonstics name</th>
                    <th>Payment Method</th> 
                    <th>Total Amount</th> 
                    <th>Address</th> 
                    <th>Action</th>
                </tr>
            </thead>
            <tbody className="table__body">

                {
                    data && data.map((item, i) => (
                        <tr key={item.id}>
                            <td>{item?.doc?.name}</td>
                            <td>{item?.doc?.number}</td>
                            <td>{item?.doc?.email}</td>
                            <td>{item?.doc?.gender}</td>
                            <td>{item?.doc?.age}</td>
                            <td>{item?.doc?.living}</td>
                            <td>{item?.doc?.items.map(item => `${item.Name} (${item.amount}),`)}</td>
                            <td>{item?.doc?.paymentMethod}</td>
                            <td>{item?.doc?.totalAmount}</td>
                            <td>{item?.doc?.address}</td>
                            <td>
                                <div>
                                    {/* <img style={{ width: "30px", height: "30px" }} src={editIcon} alt="edit icon" /> */}
                                    <img style={{ width: "30px", height: "30px" }} onClick={() => handleDelete(item?.id)} src={deleteIcon} alt="delete icon" />
                                </div>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        </div>
    )
}
