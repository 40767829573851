import {
  Home as HomeView,
  About as AboutView,
  Contact as ContactView,
  Hospitals as HospitalsView
} from "./views"
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import ReactGA from "react-ga"

import "./App.css"
import { analytics, db } from "./firebase.config";
import { collection, getDocs } from "firebase/firestore";
import {logEvent } from "firebase/analytics"
import { useEffect, useState } from "react";
import Admin from "./views/Dashboard/Admin";
import TelemedicineFormPage from "./views/Dashboard/TelemedicineFormPage/TelemedicineFormPage";
import VaccineListPage from "./views/Dashboard/VaccineListPage/VaccineListPage";
import DashboardFormlist from "./views/Dashboard/DashboardFormlist/DashboardFormlist";
import DashboardHospital from "./views/Dashboard/DashboardHospital/DashboardHospital";
import DashboardHospitalAdd from "./views/Dashboard/DashboardHospitalAdd/DashboardHospitalAdd";
import DashboardSymptoms from "./views/Dashboard/DashboardSymptoms/DashboardSymptoms";
import DashboardSymptomsAdd from "./views/Dashboard/DashboardSymptomsAdd/DashboardSymptomsAdd";
import DashboardTreatments from "./views/Dashboard/DashboardTreatments/DashboardTreatments";
import DashboardTreatmentsAdd from "./views/Dashboard/DashboardTreatmentsAdd/DashboardTreatmentsAdd";
import DashboardDiagonostics from "./views/Dashboard/DashboardDiagonostics/DashboardDiagonostics";
import DashboardDiagonosticsAdd from "./views/Dashboard/DashboardDiagonosticsAdd/DashboardDiagonosticsAdd";
import Diagonostics from "./views/Diagonostics";
import Checkout from "./views/Checkout/Checkout";
import Vaccine from "./views/Vaccine/Vaccine";
import TeleMedicine from "./views/TeleMedicine/TeleMedicine";
import BecameAPartner from "./views/BecameAPartner/BecameAPartner";
import Treatments from "./views/Treatments/Treatments";
import DashboardBecamePartner from "./views/Dashboard/DashboardBecamePartner/DashboardBecamePartner";
import DiagonsticsSelected from "./views/DiagonsticsSelected/DiagonsticsSelected";
import SignUp from "./views/SignUp/SignUp";
import Login from "./views/Login/Login";
import { useAuthContext } from "./hooks/useAuthContext";
import DiagonsticsCheckout from "./views/DiagonsticsCheckout/DiagonsticsCheckout";
import HospitalDetails from "./views/Hospitals/components/HospitalDetails/HospitalDetails";
import PatientJourney from "./views/PatientJourney/PatientJourney";
import Products from "./views/Products/Products";
import DiagonosticsOrder from "./views/Dashboard/DiagonosticsOrder/DiagonosticsOrder";
import VaccineOrder from "./views/Dashboard/VaccineOrder/VaccineOrder";
import VaccineSelected from "./views/VaccineSelected/VaccineSelected";
import VaccineCheckout from "./views/VaccineCheckout/VaccineCheckout";

function App() {
  let [adultBodySegment, setAdultBodySegment] = useState([])
  let [womenBodySegment, setWomenBodySegment] = useState([])
  let [babyBodySegment, setBabyBodySegment] = useState([])
  let [category, setCategory] = useState("head of adult")
  let [organs, setOrgans] = useState([])
  let [selectOrgans, setSelectOrgans] = useState(category)
  let [symptoms, setSymptoms] = useState([])


  let userOrganCollectionRef = collection(db, "organs")
  let userSymptomCollectionRef = collection(db, "symptom")
  let adultBodyCollectionRef = collection(db, "bodyParts")
  let womenBodyCollectionRef = collection(db, "bodyPartWomen")
  let babyBodyCollectionRef = collection(db, "bodyPartBaby")

  const TRACKING_ID = "UA-213501466-4"; 
  
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.path + window.location.search)
    // logEvent(analytics, 'from home page');


    const getOrgans = async () => {
      const data = await getDocs(userOrganCollectionRef)
      setOrgans(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
    }

    const getSymptoms = async () => {
      const data = await getDocs(userSymptomCollectionRef)
      setSymptoms(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
    }

    const getAdultBodysegment = async () => {
      const data = await getDocs(adultBodyCollectionRef)
      setAdultBodySegment(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
    }

    const getWomenBodysegment = async () => {
      const data = await getDocs(womenBodyCollectionRef)
      setWomenBodySegment(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
    }
    const getBabyBodysegment = async () => {
      const data = await getDocs(babyBodyCollectionRef)
      setBabyBodySegment(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
    }

    getOrgans()
    getSymptoms()
    getAdultBodysegment()
    getWomenBodysegment()
    getBabyBodysegment()
  }, [])

  const { user } = useAuthContext()

  


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeView category={category} setCategory={setCategory} adultBodySegment={adultBodySegment} womenBodySegment={womenBodySegment} babyBodySegment={babyBodySegment} selectOrgans={selectOrgans} setSelectOrgans={setSelectOrgans} organs={organs} setOrgans={setOrgans} symptoms={symptoms} />} />

        <Route path="/about-us" element={<AboutView />} />
        <Route path="/contact-us" element={<ContactView />} />

        <Route path="/hospitals" element={<HospitalsView />} />
        <Route path='/hospitals/:id' element={<HospitalDetails />} />
        <Route path="/diagonostics" element={<Diagonostics />} />
        <Route path="/diagonostics/selected" element={<DiagonsticsSelected />} />
        <Route path="/vaccine/selected" element={<VaccineSelected />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/vaccine" element={<Vaccine />} />
        <Route path="/tele-medicine" element={<TeleMedicine />} />
        <Route path="/partner" element={<BecameAPartner />} />
        <Route path="/treatments" element={<Treatments />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/patient-journey" element={<PatientJourney />} />
        <Route path="/diagonstics/checkout" element={<DiagonsticsCheckout />} />
        <Route path="/vaccine/checkout" element={<VaccineCheckout />} />
        <Route path="/products" element={<Products />} />

        {/* admin page */}
        {
          user && <Route path="/admin" element={<Admin />} />
        }
        {
          !user && <Route path="/admin" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/telemedicine" element={<TelemedicineFormPage />} />
        }
        {
          !user && <Route path="/admin/telemedicine" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/vaccine" element={<VaccineListPage />} />
        }
        {
          !user && <Route path="/admin/vaccine" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/form-list" element={<DashboardFormlist />} />
        }
        {
          !user && <Route path="/admin/form-list" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/hospital-list" element={<DashboardHospital />} />
        }
        {
          !user && <Route path="/admin/hospital-list" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/hospital-list/add" element={<DashboardHospitalAdd />} />
        }
        {
          !user && <Route path="/admin/hospital-list/add" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/symptom-list" element={<DashboardSymptoms />} />
        }
        {
          !user && <Route path="/admin/symptom-list" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/symptoms/add" element={<DashboardSymptomsAdd />} />
        }
        {
          !user && <Route path="/admin/symptoms/add" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/treatments" element={<DashboardTreatments />} />
        }
        {
          !user && <Route path="/admin/treatments" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/treatments/add" element={<DashboardTreatmentsAdd />} />
        }
        {
          !user && <Route path="/admin/treatments/add" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/diagonostics" element={<DashboardDiagonostics />} />
        }
        {
          !user && <Route path="/admin/diagonostics" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/diagonostics/add" element={<DashboardDiagonosticsAdd />} />
        }
        {
          !user && <Route path="/admin/diagonostics/add" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/partner" element={<DashboardBecamePartner />} />
        }
        {
          !user && <Route path="/admin/partner" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/diagonostics-order" element={<DiagonosticsOrder />} />
        }
        {
          !user && <Route path="/admin/diagonostics-order" element={<Navigate replace to="/login" />} />
        }
        {
          user && <Route path="/admin/vaccine-order" element={<VaccineOrder />} />
        }
        {
          !user && <Route path="/admin/vaccine-order" element={<Navigate replace to="/login" />} />
        }

        <Route
          path="*"
          element={
            <main className="nothing__page">
              <div className="container">
                <h2>Coming soon</h2>
              </div>
            </main>
          }
        />
      </Routes>
    </div>
  );
}

export default App;



// Track your detail in Google Analytics
