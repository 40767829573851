import { createContext, useReducer } from "react";

export const DetailContext = createContext()

export const detailReducer = (detail, {type, payload}) => {
    switch(type) {
        case "HOME_DETAIL": 
            return {...detail, detail: payload}

        case "VACCINE_DETAIL":
            return {...detail, detail: payload}

        case "TELEMEDICINE_DETAIL":
            return {...detail, detail: payload}

        case "NULL":
            return {detail: null}
            
        default: 
            return detail
    }
}

export const DetailContextProvider = ({children}) => {
    const [detail, dispatch] = useReducer(detailReducer, {detail: null})
    return (
        <DetailContext.Provider value = {{...detail, dispatch}}>
            {children}
        </DetailContext.Provider>
    )
}