import { collection, getDocs } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import DashboardFormTitle from '../../../components/organisms/DashboardFormTitle/DashboardFormTitle'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'
import { db } from '../../../firebase.config'
import { useCollection } from '../../../hooks/useCollection'


import classes from './TelemedicineFormPage.module.css'
import TeleMedicineTable from './TeleMedicineTable/TeleMedicineTable'

export default function TelemedicineFormPage() {
    const {document: allList} = useCollection("all__list")
    const telemedicine = allList.filter(data => data?.doc?.dataFrom === "teleMedicine")
    return (
        <div>
           
            <div className={classes.telemedicine}>
                <DashboardSidebar />

                <div className={classes.telemedicine__form}>
                    <DashboardFormTitle title="Telemedicine Form List" />
                    <div className={classes.telemedicine__table}>
                        <TeleMedicineTable data={telemedicine} />
                    </div>
                </div>
            </div>
        </div>
    )
}

