import React from 'react'
import { Link } from 'react-router-dom'

import classes from "./VaccineSelected.module.css"
import SelectedVaccine from './SelectedVaccine/SelectedVaccine'
import { useVaccineContext } from '../../hooks/userVaccineContext'

export default function VaccineSelected() {
    const {items, totalAmount, removeItem} = useVaccineContext()
    return (
        <>
            <div className='container'>
                <h2 style={{ fontSize: "20px", color: "blue" }}>Selected Item</h2>
                <div className={`${classes.card} diagnostics_table`}>
                    <table>
                        <thead className='table__heading'>
                            <th>ExamName</th>
                            <th>Price</th>
                            <th>select</th>
                            <th>Category</th>
                            <th>Action</th>
                        </thead>
                        <tbody className='table__body'>
                            {
                                items.map(item => <SelectedVaccine item={item} removeItem={removeItem.bind(null, item.id)} />)
                            }
                            <tr className={classes.row}>
                                <td></td>
                                <td><b>Total Price : {totalAmount} BDT</b></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {
                    items.length !== 0 && <div className={classes.btn__container}>
                        <Link to="/vaccine/checkout"><button className='btn__blue'>Checkout</button></Link>
                    </div>
                }

            </div>
        </>
    )
}
