import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useVaccineContext } from '../../../hooks/userVaccineContext';
import covidImg from "../../../assets/images/covid.png"

export default function VaccineCard() {
    let [selectVaccine, setSelectVaccine] = useState("")
    const { addItem, items, totalAmount } = useVaccineContext()

    const handleChange = e => {
        setSelectVaccine(e.target.value)
    }

    const handleVaccine = () => {
        let data
        if (selectVaccine == "") return
        if (selectVaccine == "RT-PCR") {
            data = {
                Name: "RT-PCR",
                Price: 3200,
                id: "RT1"
            }
        }
        if (selectVaccine == "TRT-PCR") {
            data = {
                Name: "Travellers RT-PCR",
                Price: 2500,
                id: "TRT1"
            }
        }

        addItem({ ...data, amount: 1 , id: data.id})
    }

    return (
        <div className='grid__container grid__coloumn_6 vaccine_card'>
            <div className='vaccine_left_card'>
                <div>
                    <h2>Conduct Your COVID-19 Test from DGHS-Approved Lab.</h2>
                    <p>Highest quality standard and all health precaution maintained during sample collection.</p>
                </div>
                <p>*500 Taka Home Sample Collection Charge To Be Added</p>
            </div>
            <div className='vaccine_left'>
                <h2>Book a COVID-19 Test now</h2>
                <div>
                    <p>COVID-19 Test </p>
                    <p>2700* Taka</p>
                </div>
                <div>
                    <p>Traveler’s COVID-19 Test </p>
                    <p>2500 Taka</p>
                </div>
                <select className="selected" name="cars" onChange={handleChange} id="cars">
                    <option value="">Select COVID Test</option>
                    <option value="RT-PCR">RT-PCR for COVID-19</option>
                    <option value="TRT-PCR">Traveler’s RT-PCR for COVID-19</option>
                </select>
                <Link to="/vaccine/selected"><button onClick={handleVaccine}>Continue</button></Link>
            </div>
        </div>
    );
}
