

import classes from "./Checkout.module.css"
import Billing from './components/Billing/Billing'
import Payment from './components/Payment/Payment'

export default function Checkout() {
    return (
        <div className={classes.checkout}>
            <div className='content__container'>
                <div className={classes.checkout__details}>
                    <Billing />
                    {/* <Payment /> */}
                </div>
            </div>
        </div>
    )
}
