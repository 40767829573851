import React from 'react'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'


import classes from "./DashboardDiagonosticsAdd.module.css"
import DiagonosticsFormAdd from './DiagonosticsFormAdd'

export default function DashboardDiagonosticsAdd() {
    return (
        <div>
            
            <div className={classes.dashboard__form}>
                <DashboardSidebar />

                <div className={classes.dashbard__form_add}>
                    <DiagonosticsFormAdd btnTitle="Add Diagonostics"  />
                </div>
            </div>
        </div>
    )
}
