import React from 'react'
import aboutImg from './../../../../assets/images/about1.png'
import classes from './AboutHealthPort.module.css'

export default function AboutHealthPort() {
    return (
        <div className='grid__container grid__coloumn_6'>
            <img src={aboutImg} alt='HealthPort Bangladesh' />
            <div className={`${classes.about_healthport}`}>
                {/* <span>About</span> */}
                <h3>Healthport Bangladesh</h3>
                <p style={{fontSize: "1.6rem"}}>Healthport Bangladesh is a world class health-tech and health tourism hub to facilitate health services where people can sail through and navigate to the right destinations with patients for health advise and medical treatment. The services are provided in partnership with the most modern and well-resourced hospitals across the globe by embedding complete packages of customized care, comfort and treatment. It provides end to end health care tourism and associated support services with appropriate doctors’ advice, medical treatments and second opinions,    follow-ups and diagnostic tests from your doorstep.</p>
            </div>
        </div>
    )
}
