import React from 'react'
import classes from "./SelectedVaccine.module.css"
import deleteIcon from '../../../assets/images/delete.png'

export default function SelectedVaccine({ item, removeItem }) {
    const {Name, Price, amount, id} = item
    const handleRemove = (id) => {
        console.log(id)
        removeItem(id)
    }
    return (
        <tr className={classes.row}>
            <td>{Name}</td>
            <td>{Price}</td>
            <td>{amount}</td>
            <td onClick={() => handleRemove(id)}><img style={{width: "30px", height:"30px"}} src={deleteIcon} alt="delete" /></td>
        </tr>
    )
}
