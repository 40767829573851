import React, { useState } from 'react'
import Button from '../../../../components/molecules/Button/Button'
import { useCollection } from '../../../../hooks/useCollection'
import { useFireStore } from '../../../../hooks/useFireStore'
import classes from "./SymptomAddForm.module.css"

export default function SymptomAddForm({ btnTitle }) {
    let [symptomName, setSymptomName] = useState("")
    let [category, setCategory] = useState("")

    const { addDocument } = useFireStore("symptom")
    const { document: organs } = useCollection("organs")
    const organName = organs.map(organ => organ.name)
    const uniqueOrgan = [... new Set(organName)]


    const handleSubmit = e => {
        e.preventDefault()
        const symptomsData = {
            name: symptomName,
            category: category
        }
        
        addDocument(symptomsData)
    }

    return (
        <div className={classes.dashboardForm}>
            <h2 className={classes.dashboardTitle}>{btnTitle}</h2>
            <form onSubmit={handleSubmit} className={classes.form}>
                <div className={`${classes.contact_form} grid__container grid__coloumn_6`}>
                    <div className={`${classes.input}`}>
                        <input type='text' placeholder='Symptoms Name' onChange={e => setSymptomName(e.target.value)} />
                    </div>
                    <div className='filter'>
                        <select className='selected' name="cars" onChange={e => setCategory(e.target.value)} id="cars">
                            {
                                uniqueOrgan.map(organ => (
                                    <option value={organ}>{organ}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <Button className="flex">{btnTitle}</Button>
            </form>
        </div>
    )
}
