import React, {useState, useEffect} from 'react'
import DashboardElements from './DashboardElements/DashboardElements'
import DashboardGreetings from './DashboardGreetings/DashboardGreetings'

import classes from "./Admin.module.css"
// import analytics
import { checkSignedIn, renderButton } from './utils';
import Report from './report';

export default function Admin() {
    // analytics code
    const [isSignedIn, setIsSignedIn] = useState(false);

    const updateSignin = (signedIn) => { //(3)
        setIsSignedIn(signedIn);
        if (!signedIn) {
            renderButton();
        }
    };

    const init = () => { //(2)
        checkSignedIn()
            .then((signedIn) => {
                updateSignin(signedIn);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        window.gapi.load("auth2", init); //(1)
    });
    return (
        <div className={classes.admin}>

            {!isSignedIn ? (
                <div id="signin-button"></div>
            ) : (
                <Report />
            )}
        </div>
    )
}
