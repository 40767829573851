import React from 'react'
import classes from "./TopNavigation.module.css"
// import navLogo from '../assets/images/healthport-logo-blue.png'
import navLogo from '../assets/images/logoPng.png'
import { Link } from 'react-router-dom'
import ReactGA from "react-ga"

export default function TopNavigation() {
    const handleMedicalAdvice = () => {
        ReactGA.event({
            category: "button",
            action: "click on medical Advice button"
        })
    }
    return (
        <div className={classes.top__navigation_container}>
            <nav className={classes.top__navigation}>
                <Link to="/"><img className={classes.top__navigation_logo} src={navLogo} alt="healthport logo" /></Link>
                <div className={classes.form_container}>
                    <Link to="/checkout"><button className={classes.form__button} onClick={handleMedicalAdvice}>Click to Get Medical Advice</button></Link>
                </div>
            </nav>
        </div>
    )
}

