import React from 'react'
// import DashboardForm from '../../../components/organisms/DashboardForm/DashboardForm'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'


import classes from "./DashboardSymptomsAdd.module.css"
import SymptomAddForm from './SymptomAddForm/SymptomAddForm'

export default function DashboardSymptomsAdd() {
    return (
        <div>
            <div className={classes.dashboard__form}>
                <DashboardSidebar />

                <div className={classes.dashbard__form_add}>
                    <SymptomAddForm btnTitle = "Add Symptom"  />
                </div>
            </div>
        </div>
    )
}
