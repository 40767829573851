import React, { useEffect, useState } from 'react'
import { useCollection } from '../../hooks/useCollection'
import { Hospital } from './components/Hospital'
import classes from "./Hospitals.module.css"
import loadingSpinner from "../../assets/images/loadingSpinner.gif"

export default function Hospitals() {
    
    let [country, setCountry] = useState("")
    let [location, setLocation] = useState("")
    let [tag, setTag] = useState("")
    let [show, setShow] = useState(false)
    const { document: hospitalData, isLoading } = useCollection("hospitals")
    // console.log(hospitalData)

    const [data, setData] = useState(hospitalData);


    // exclude column list from filter
    const excludeColumns = ["hospitalDescription", "hospitalImg"];
    

    const handleCountry = e => {
        setCountry(e.target.value)
        setLocation("")
        setTag("")
        filterData(e.target.value)
        setShow(false)
    }

    const handleLocation = e => {
        setLocation(e.target.value)
        setTag("")
        filterData(e.target.value)
        setShow(false)
    }

    const handleTags = e => {
        setTag(e.target.value)
        setShow(true)
        // setLocation("")
        // setCountry("")
        // filterData(e.target.value)
    }

    // filter records by search text
    const filterData = (value) => {
        const lowercasedValue = value.toLowerCase().trim();
        if (lowercasedValue === "") setData(hospitalData);
        else {
            const filteredData = hospitalData.filter(item => {
                return Object.keys(item.data).some(key =>
                    excludeColumns.includes(key) ? false : item.data[key].toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setData(filteredData);
        }
    }


    // Country wise tagh
    const countries = hospitalData.filter(item => item.data.country.toLowerCase() === country.toLowerCase())

    const countryName = hospitalData.map(item => item.data.country)
    const uniqueCountryName = [... new Set(countryName)]
    console.log(uniqueCountryName)

    const countryWiseLocation = [... new Set(countries.map(item => item.data.location.toLowerCase()))]


    const countryWisetag = countries.map(item => item.data.hospitalTags)
    let mergedCountryWiseTag = [... new Set([].concat.apply([], countryWisetag))]

    const taged = hospitalData.map(data => data.data.hospitalTags)
    const allTaged = [... new Set([].concat.apply([], taged))]


    const tagWiseData = data.filter(item => item.data.hospitalTags.includes(tag.toLowerCase()))
    const tagWiseShowingData = tagWiseData && tagWiseData?.map(hospital => <Hospital hospital={hospital} />)

    // const allTagWiseData = hospitalData.filter(item => item.data.hospitalTags.includes(tag))
    // const allTagWiseShowingData = allTagWiseData && allTagWiseData?.map(hospital => <Hospital hospital={hospital} />)


    return (
        <div className="content__container section-pd">
            <div className={classes.filterContainer}>
                <div className="filter">
                    <label className='filter__label' for="country">Search by country</label>
                    <select className="selected" defaultValue="  " name="country" onChange={handleCountry} id="country">
                        <option value="   ">All</option>
                        {
                            uniqueCountryName && uniqueCountryName.map(item => (
                                <option value={item}>{item}</option>
                            ))
                        }
                    </select>
                </div>
                {
                    country !== "India" && country !== "Turkey" && country !== "Singapore" && country !== "Thailand" && country !== "UAE" && <div disabled className="filter">
                        <label className='filter__label' for="locations">Search by location</label>
                        <select className="selected" name="locations" id="locations">
                            <option >Select A Country First</option>
                        </select>
                    </div>
                }
                {
                    (country === "Turkey" || country === "India" || country === "Thailand" || country === "Singapore" || country === "UAE") &&  <div className="filter">
                        <label className='filter__label' for="locations">Search by location</label>
                        <select className="selected" name="locations" onChange={handleLocation} id="locations">
                            <option value="">Select A location</option>
                            {
                                countryWiseLocation && countryWiseLocation.map(item => (
                                    <option value={item.toLowerCase()}>{item}</option>
                                ))
                            }
                        </select>
                    </div>
                }
                <div className="filter">
                    <label className='filter__label' for="tags">Search by Tag</label>
                    <select className="selected" name="tags" onChange={handleTags} id="tags">
                        <option >Select A Tag</option>
                        {
                            mergedCountryWiseTag.length ? mergedCountryWiseTag.map(item => (
                                <option value={item.toLowerCase()}>{item}</option>
                            )) : allTaged.map(item => (
                                <option value={item.toLowerCase()}>{item}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="grid__container grid__coloumn_4">

                {
                    isLoading ? <div className="loadingImg"> <img src={loadingSpinner} /> </div> : show ? tagWiseShowingData : data.length === 0 ? hospitalData?.map(hospital => <Hospital hospital={hospital} />) : data?.map(hospital => <Hospital hospital={hospital} />)
                }

            </div>
        </div >
    )
}
