import React from 'react'
import { useCollection } from '../../../hooks/useCollection'

import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'
import DiagonsticsFormAdd from '../DashboardDiagonostics/DiagonsticsFormAdd'
import classes from "./DashboardBecamePartner.module.css"
import BecamePartnerTable from './BecamePartnerTable/BecamePartnerTable'


export default function DashboardBecamePartner() {
    let { document: partnerData } = useCollection("became_a_partner")
    return (
        <div>
            <div className={classes.diagonostics}>
                <DashboardSidebar />

                <div className={classes.diagonostics__form}>
                    {/* <DiagonsticsFormAdd path="/diagonostics/add" title="Became Partner List" collectionName="diagonstics" setSearch={setSearch} setCategory={setCategory} /> */}
                    <div className={classes.diagonostics__table}>
                        <BecamePartnerTable data={partnerData} />
                    </div>
                </div>
            </div>
        </div>
    )
}
