import React from 'react'

import classes from "./DashboardTable.module.css"

import editIcon from '../../../assets/images/edit.png'
import deleteIcon from '../../../assets/images/delete.png'

export default function DashboardTable({ data }) {

    const statusClass = (status) => {
        var classes = ""

        if (status === "pending") {
            classes = "pending"
        } else {
            classes = "done"
        }

        return classes
    }


    return (
        <table>
            <thead className={classes.table__heading}>
                <tr>
                    <th>SL NO</th>
                    <th>Requested Date</th>
                    <th>Patient Email / Phone</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody className={classes.table__body}>

                {
                    data && data.map((item, i) => (
                        <tr>
                            <td>{i + 1}</td>
                            <td>{item.date}</td>
                            <td>{item.email}</td>
                            <td>
                                <div>
                                    <img style={{ width: "30px", height: "30px" }} src={editIcon} alt="edit icon" />
                                    <img style={{ width: "30px", height: "30px" }} src={deleteIcon}alt="delete icon" />
                                </div>

                                {/* <select className={statusClass(item.status)} >
                                    <option value="pending">{item.status}</option>
                                    <option value="done">Done</option>
                                </select> */}
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
