import React from 'react'
import { Link } from 'react-router-dom'

import searchIcon from '../../../assets/images/searchIcon.png'
import plusIcon from '../../../assets/images/plusIcon.png'
import classes from "./DiagonsticsFormAdd.module.css"

export default function DiagonsticsFormAdd({ title, path, setCategory, setSearch }) {

    return (
        <div className={classes.title__container}>
            <h4 className={classes.title}>{title}</h4>
            <div className={classes.input__container}>
                {/* <div className={classes.input}>
                    <img className={classes.input__img} src={searchIcon} alt="search Icon" />
                    <input className={classes.input__field} onChange={e => setSearch(e.target.value)} type="text" placeholder="" />
                </div> */}
                <div className={classes.input}>
                    <div className=' className="filter"'>
                        <label className='filter__label' for="cars">Search by category</label>
                        <select className='selected' name="cars" onChange={e => setCategory(e.target.value)} id="cars">
                            <option value="all">ALL Category</option>
                            <option value="Tissue Test">Tissue Test</option>
                            <option value="Blood Test">Blood Test</option>
                            <option value="Imaging">Imaging</option>
                            <option value="Stool">Stool</option>
                            <option value="Sputum Test">Sputum Test</option>
                            <option value="Urine Test">Urine Test</option>
                            <option value="Body Fluid Test">Body Fluid Test</option>
                            <option value="Swab Test">Swab Test</option>
                        </select>
                    </div>
                    {/* <img className={classes.input__img} src={filterIcon} alt="filter Icon" />
                    <input className={classes.input__field} onChange={e => setCategory(e.target.value)} type="text" placeholder="Filters" /> */}
                </div>
                <Link to={`/admin${path}`} style={{ textDecoration: "none", color: "#fff" }}>
                    <button className={classes.button__container}>
                        <img className={classes.button__img} src={plusIcon} alt="filter Icon" />
                        Add
                    </button>
                </Link>
            </div>
        </div>
    )
}
