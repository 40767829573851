import React from 'react'
import facebook from "../../../../assets/images/facebook.png"
import linkedin from "../../../../assets/images/linkedin.png"

import classes from "./ContactSocial.module.css"

export default function ContactSocial() {
    return (
        <div className={classes.social_container}>  
            <div className={classes.details}>
                <div>Phone : <p>+880 1312 872136</p></div>
                <div>Email : <p>info@healthport.com.bd</p></div>
                <div>Website : <p>healthport.com.bd</p></div>
                <div>Address : <p>2<sup>nd</sup> Floor, InnStar Tree House, House 50, Road 23, Banani, Dhaka-1213</p></div>
                <div>Facebook : <a href='https://www.facebook.com/healthportbangladesh' target="_blank">https://www.facebook.com/healthportbangladesh</a></div>
                <div>Linkedin : <a href='https://www.linkedin.com/company/healthportbangladesh' target="_blank">https://www.linkedin.com/company/healthportbangladesh</a></div>
                
            </div>
            {/* <div className={`${classes.socal__icons}`}>
                <a href='https://www.facebook.com/healthportbangladesh/' target="_blank" ><img className={classes.facebook} src={facebook} alt="facebook" /></a>
                <a href='https://www.linkedin.com/company/healthportbangladesh/?originalSubdomain=bd' target="_blank" ><img src={linkedin} alt="linkedin" /></a>
            </div> */}
        </div>
    )
}
