import React from 'react'
import editIcon from '../../../../assets/images/edit.png'
import deleteIcon from '../../../../assets/images/delete.png'

import { deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../../../firebase.config'

export default function BecamePartnerTable({data}) {
    const handleDelete = async(id) => {
        const ref = doc(db, "became_a_partner", id)
        await deleteDoc(ref)
    }
    return (
        <div className='tableScrool'>
        <table>
            <thead className="table__heading">
                <tr>
                    <th>SL NO</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Company Name</th>
                    <th>Company Type</th>
                    <th>Contact Person</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody className="table__body">
                {
                    data && data.map((item, i) => (
                        <tr key = {item.id}>
                            <td>{i + 1}</td>
                            <td>{item?.doc?.email}</td>
                            <td>{item?.doc?.phoneNumber}</td>
                            <td>{item?.doc?.address}</td>
                            <td>{item?.doc?.companyName}</td>
                            <td>{item?.doc?.companyType}</td>
                            <td>{item?.doc?.contactPerson}</td>
                            <td>
                                <div>
                                    <img style={{ width: "30px", height: "30px" }} src={editIcon} alt="wrong icon" />
                                    <img style={{ width: "30px", height: "30px" }} onClick={() => handleDelete(item.id)} src={deleteIcon} alt="delete icon" />
                                </div>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        </div>
    )
}
