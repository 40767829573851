import React from 'react'
import { useDiagonstics } from '../../hooks/useDiagonstics'
import { Link } from 'react-router-dom'

import classes from "./DiagonsticsSelected.module.css"
import SelectedItems from './SelectedItems/SelectedItems'

export default function DiagonsticsSelected() {
    const diagonsticsSelected = useDiagonstics()
    return (
        <>
            <div className='container'>
                <h2 style={{ fontSize: "20px", color: "blue" }}>Selected Item</h2>
                <div className={`${classes.card} diagnostics_table`}>
                    <table>
                        <thead className='table__heading'>
                            <th>ExamName</th>
                            <th>Price</th>
                            <th>select</th>
                            <th>Category</th>
                            <th>Action</th>
                        </thead>
                        <tbody className='table__body'>
                            {
                                diagonsticsSelected.items.map(item => <SelectedItems item={item} removeItem={diagonsticsSelected.removeItem.bind(null, item.id)} />)
                            }
                            <tr className={classes.row}>
                                <td></td>
                                <td><b>Total Price : {diagonsticsSelected.totalAmount} BDT</b></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {
                    diagonsticsSelected.items.length !== 0 && <div className={classes.btn__container}>
                        <Link to="/diagonstics/checkout"><button className='btn__blue'>Checkout</button></Link>
                    </div>
                }

            </div>
        </>
    )
}
