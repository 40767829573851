import React from 'react'
import Footer from './Footer'
import MainNavigation from './MainNavigation'
import TopNavigation from './TopNavigation'

export default function LayOut({children}) {
    return (
        <div>
            <TopNavigation />
                {children}
            <MainNavigation />
            <Footer />
        </div>
    )
}
