import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { useCollection } from "../../hooks/useCollection"
import { useDiagonstics } from '../../hooks/useDiagonstics'
import IndividualDiagonostics from './components/IndividualDiagonostics/IndividualDiagonostics'

import classes from "./Diagonostics.module.css"
import loadingSpinner from "../../assets/images/loadingSpinner.gif"

import ReactPaginate from "react-paginate";
import VaccineCard from './components/VaccineCard'

export default function Diagonostics() {
    const { document: diagonosticsData, isLoading } = useCollection("diagnostics")

    // Pagination code start
    const users = diagonosticsData
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 30;
    const pagesVisited = pageNumber * usersPerPage;

    const displayData = users
        .slice(pagesVisited, pagesVisited + usersPerPage)
        .map((data) => {
            return (
                <IndividualDiagonostics data={data} />
            );
        });

    const pageCountAll = Math.ceil(users.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    // Pagination code End here

    let [show, setShow] = useState(false)
    let [category, setCategory] = useState("")
    let [searchByName, setSearchByName] = useState("  ")


    let filterredData = diagonosticsData.filter(doc => doc.Category === category)

    const myData = filterredData.length > 0 && filterredData.slice(pagesVisited, pagesVisited + usersPerPage)
        .map((data) => {
            return (
                <IndividualDiagonostics data={data} />
            );
        })

    const pageCountFilter = Math.ceil(filterredData.length / usersPerPage);

    const searchedByName = diagonosticsData.length !== 0 && diagonosticsData.filter(item => item.Name.toLowerCase().trim().includes(searchByName.toLowerCase().trim())).slice(pagesVisited, pagesVisited + usersPerPage)
        .map((data) => {
            return (
                <IndividualDiagonostics data={data} />
            );
        })

    const handleFilter = e => {
        setCategory(e.target.value)
        setShow(false)
    }


    const handleSearch = e => {
        setSearchByName(e.target.value)
        setShow(true)
    }

    const { items, totalAmount } = useDiagonstics()

    return (
        <section className={classes.diagonostics}>
            <div className='content__container'>
                <VaccineCard />
                <div className='flex__cart'>
                    <div className="filter">
                        <label className='filter__label' for="cars">Search by Category</label>
                        <select className="selected" name="cars" onChange={handleFilter} id="cars">
                            <option value="All">All Category</option>
                            <option value="Blood Test">Blood Test</option>
                            <option value="Tissue Test">Tissue Test</option>
                            <option value="Imaging">Imaging</option>
                            <option value="Stool">Stool</option>
                            <option value="Sputum Test">Sputum Test</option>
                            <option value="Urine Test">Urine Test</option>
                            <option value="Body Fluid Test">Body Fluid Test</option>
                            <option value="Swab Test">Swab Test</option>
                        </select>
                    </div>
                    <div className="filter">
                        <label className='filter__label' for="cars">Search by Name
                        </label>
                        <input className='selected' type="text" onChange={handleSearch} />
                    </div>
                    {
                        items.length !== 0 && <div className='filter'><Link to="/diagonostics/selected" style={{ textDecoration: "none" }}><button className='btn__blue text_xl'>Place Request for Test ({items.length})</button></Link></div>
                    }
                </div>
                <div className='grid__container grid__coloumn_4'>
                    {
                        isLoading ? <div className="loadingImg"> <img src={loadingSpinner} /> </div> : !show ? myData ? myData : displayData : searchedByName
                    }
                </div>
                <div className='pagination_container' style={{ marginTop: "5rem" }}>
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCountFilter ? pageCountFilter : pageCountAll}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />
                </div>
            </div>
        </section>
    )
}
