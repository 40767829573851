import React from 'react'
import { Link } from 'react-router-dom'
import { useCollection } from "../../../hooks/useCollection"

import searchIcon from '../../../assets/images/searchIcon.png'
import filterIcon from '../../../assets/images/filterIcon.png'
import plusIcon from '../../../assets/images/plusIcon.png'
import classes from "./DashboardFormAdd.module.css"

export default function DashboardFormAdd({ title, path, setSearch }) {

    return (
        <div className={classes.title__container}>
            <h4 className={classes.title}>{title}</h4>
            <div className={classes.input__container}>
                {/* <div className={classes.input}>
                    <img className={classes.input__img} src={searchIcon} alt="search Icon" />
                    <input className={classes.input__field} type="text" placeholder="Search" onChange={e => setSearch(e.target.value)} />
                </div>
                <div className={classes.input}>
                    <img className={classes.input__img} src={filterIcon} alt="filter Icon" />
                    <input className={classes.input__field} type="text" placeholder="Filters" />
                </div> */}
                <Link to={`/admin${path}`} style={{ textDecoration: "none", color: "#fff" }}>
                    <button className={classes.button__container}>
                        <img className={classes.button__img} src={plusIcon} alt="filter Icon" />
                        Add
                    </button>
                </Link>
            </div>
        </div>
    )
}
