import React from 'react'

import { ContactTitle, ContactForm} from './components'
import ContactMap from './components/ContactMap/ContactMap'
import ContactSocial from './components/ContactSocial/ContactSocial'
import classes from "./Contact.module.css"

export default function Contact() {
    return (
        <div className={`container section-pd ${classes.contact}`}>
            <ContactTitle />
            {/* <ContactForm /> */}
            <div className={classes.grid}>
            <ContactMap />
            <ContactSocial />
            </div>
        </div>
    )
}
