import React from 'react'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'
import { useCollection } from '../../../hooks/useCollection'

import classes from "./VaccineOrder.module.css"
import VaccineOrderTable from './VaccineOrderTable'

export default function VaccineOrder() {
    const {document: ordered} = useCollection("order_Vaccine")
    return (
        <div>
            <div className={classes.telemedicine}>
                <DashboardSidebar />

                <div className={classes.telemedicine__form}>
                    <div className={classes.telemedicine__table}>
                        <VaccineOrderTable data={ordered} />
                    </div>
                </div>
            </div>
        </div>
    )
}
