import React from 'react'
import DashboardForm from '../../../components/organisms/DashboardForm/DashboardForm'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'


import classes from "./DashboardHospitalAdd.module.css"
import HospitalsFormAdd from './HospitalsFormAdd'

export default function DashboardHospitalAdd() {
    return (
        <div>
            
            <div className={classes.dashboard__form}>
                <DashboardSidebar />

                <div className={classes.dashbard__form_add}>
                    <HospitalsFormAdd btnTitle="AddHospital" />
                </div>
            </div>
        </div>
    )
}
