import { useContext } from "react"
import { DiagonsticsContext } from "../context/DiagonsticsContext"

export const useDiagonstics = () => {
    const context = useContext(DiagonsticsContext)

    if(!context) {
        throw new Error("useDiagonstics must be inside an DiagonsticsProvider")
    }

    return context
}