// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "@firebase/auth";
import { getStorage} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDO9tNY7tCVDTEy5Q50OiZEXpY22BEwkNU",
  authDomain: "healthport-79428.firebaseapp.com",
  projectId: "healthport-79428",
  storageBucket: "healthport-79428.appspot.com",
  messagingSenderId: "581130747623",
  appId: "1:581130747623:web:fc7db80906184534ea6c42",
  measurementId: "G-0LN3DJPDCV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth()
export const db = getFirestore(app);
export const analytics = getAnalytics();
export const projectStorage = getStorage();