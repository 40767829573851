import React, { useState } from 'react'
import { useCollection } from '../../../../hooks/useCollection'
import { useDetailContext } from '../../../../hooks/useDetailContext'
import { useFireStore } from '../../../../hooks/useFireStore'
import classes from "./Billing.module.css"

export default function Billing() {
    const [selectBody, setSelectBody] = useState(null)
    const [selectOrgan, setSelectOrgan] = useState(null)
    const [selectsymptom, setSelectSymptom] = useState(null)
    // const [dataForm, setDataForm] = useState(null)
    const [email, setEmail] = useState(null)
    const [number, setNumber] = useState(null)
    const [name, setName] = useState(null)
    const [age, setAge] = useState(null)
    const [gender, setGender] = useState(null)
    const [address, setAddress] = useState(null)
    const [living, setLiving] = useState(null)
    const [timeOfCall, setTimeOfCall] = useState(null)
    const [preferredCountry, setPreferredCountry] = useState(null)

    const { detail, dispatch } = useDetailContext()
    const { addDocument, response } = useFireStore("all__list")
    const { document } = useCollection("organs")
    const { document: symptoms } = useCollection("symptom")

    // Male Data
    const adultHead = document.filter(data => data.category === "head of adult")
    const adultMiddle = document.filter(data => data.category === "middle of adult")
    const adultLower = document.filter(data => data.category === "lower of adult")

    let adultData = [...adultHead, ...adultMiddle, ...adultLower]

    // Female Data
    const WomenHead = document.filter(data => data.category === "head of women")
    const WomenMiddle = document.filter(data => data.category === "middle of women")
    const WomenLower = document.filter(data => data.category === "lower of women")

    let womenData = [...WomenHead, ...WomenMiddle, ...WomenLower]

    const filteredSymptoms = symptoms.filter(item => item.category === selectOrgan)

    const handleBilling = (e) => {
        e.preventDefault()

        const billingData = {
            name,
            age,
            gender,
            email,
            number,
            address,
            livingArea: living,
            preferredTimeToCall: timeOfCall,
            selectedBody: detail && detail ? detail.category : selectBody,
            selectedOrgans: detail && detail ? detail.selectOrgans : selectOrgan,
            selectedSymptoms: detail && detail ? detail.selectSymptoms : selectsymptom,
            dataFrom: detail && detail.dataFrom ? detail.dataFrom : "form",
        }
        if (name && age && email && number && address) {
            addDocument(billingData)
            dispatch({ type: "NULL" })
        }

        setSelectBody(null)
        setSelectOrgan(null)
        setSelectSymptom(null)
        setName('')
        setEmail('')
        setNumber('')
        setAge('')
        setGender('')
        setAddress('')
        setLiving('')
        setTimeOfCall('')
        setPreferredCountry('')
        setPreferredCountry('')
    }



    return (
        <div className={classes.checkout__billing}>
            <h4 className={classes.checkout__title}>Details</h4>
            <form>
                <label className={classes.input__container}>
                    <span className={classes.label}>Name</span>
                    <input className={classes.input} value={name} onChange={e => setName(e.target.value)} type="text" />
                </label>
                <label className={classes.input__container}>
                    <span className={classes.label}>Age</span>
                    <input className={classes.input} value={age} onChange={e => setAge(e.target.value)} type="number" />
                </label>
                <label className={classes.input__container}>
                    <div  className={classes.input__container}>
                        <label  className={classes.label} for="gender">Gender</label>
                        <select  className={classes.input} name="gender" onChange={e => setGender(e.target.value)} id="gender">
                            <option>Select a Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </div>
                </label>
                <label className={classes.input__container}>
                    <span className={classes.label}>Phone Number</span>
                    <input className={classes.input} value={number} onChange={e => setNumber(e.target.value)} type="number" />
                </label>
                <label className={classes.input__container}>
                    <span className={classes.label}>Email Address</span>
                    <input className={classes.input} value={email} onChange={e => setEmail(e.target.value)} type="email" />
                </label>
                <label className={classes.input__container}>
                    <span className={classes.label}>Address</span>
                    <input className={classes.input} value={address} onChange={e => setAddress(e.target.value)} type="text" />
                </label>
                <label className={classes.input__container}>
                    <span className={classes.label}>Living/Work Area</span>
                    <input className={classes.input} value={living} onChange={e => setLiving(e.target.value)} type="text" />
                </label>
                <label className={classes.input__container}>
                    <span className={classes.label}>Preferrred Time of Call</span>
                    <input className={classes.input} value={timeOfCall} onChange={e => setTimeOfCall(e.target.value)} type="time" />
                </label>
                <label className={classes.input__container}>
                    <span className={classes.label}>Preferred Country of Treatment</span>
                    <select  className={classes.input} value={preferredCountry} value={preferredCountry} onChange={e => setPreferredCountry(e.target.value)} onChange={e => setPreferredCountry(e.target.value)} name="company" id="company">
                        <option>Select A Country</option>
                        <option value="India">India</option>
                        <option value="Thailand">Thailand</option>
                        <option value="UAE">UAE</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Singapore">Singapore</option>
                    </select>
                </label>
                {
                    detail?.dataFrom && <label className={classes.input__container}>
                        <span className={classes.label}>Selected Body Region</span>
                        <input className={classes.input} value={selectBody} onChange={e => setSelectBody(e.target.value)} value={detail && detail.category} type="text" />
                    </label>
                }

                {
                    detail && detail.selectOrgans && <label className={classes.input__container}>
                        <span className={classes.label}>Selected Organ</span>
                        <input type="text" value={selectOrgan} onChange={e => setSelectOrgan(e.target.value)} className={classes.input} value={detail && detail.selectOrgans} required />
                    </label>
                }
                {
                    !detail?.dataFrom && gender === "male" && <div className={classes.input__container}>
                        <label  className={classes.label} for="gender">Organ</label>
                        <select  className={classes.input} name="organ" onChange={e => setSelectOrgan(e.target.value)} id="organ">
                            <option>Select a organ</option>
                            {
                                adultData && adultData.map(item => (
                                    <option value={item.name}>{item.name}</option>

                                ))
                            }
                        </select>
                    </div>
                }
                {
                    !detail?.dataFrom && gender === "female" && <div  className={classes.input__container}>
                        <label  className={classes.label} for="gender">Organ</label>
                        <select  className={classes.input} name="organ" onChange={e => setSelectOrgan(e.target.value)} id="organ">
                            <option>Select a organ</option>
                            {
                                womenData && womenData.map(item => (
                                    <option value={item.name}>{item.name}</option>

                                ))
                            }
                        </select>
                    </div>
                }
                {
                    !detail?.dataFrom && gender !== "male" && gender !== "female" && <div className={classes.input__container}>
                        <label  className={classes.label} for="gender">Organ</label>
                        <select  className={classes.input} name="organ" id="organ">
                            <option>First Select Gender</option>
                        </select>
                    </div>
                }


                {/* symptoms field */}
                {
                    detail && detail.selectSymptoms && <label className={classes.input__container}>
                        <span className={classes.label}>Selected Symptoms</span>
                        <input className={classes.input} value={selectsymptom} onChange={e => setSelectSymptom(e.target.value)} value={detail && detail.selectSymptoms} type="text" required />
                    </label>
                }
                {
                    !detail?.dataFrom && selectOrgan && <div  className={classes.input__container}>
                        <label  className={classes.label} for="symptom">Symptom</label>
                        <select  className={classes.input} name="symptom" onChange={e => setSelectSymptom(e.target.value)} id="symptom">
                            <option>Select a Symptom</option>
                            {
                                filteredSymptoms && filteredSymptoms.map(item => (
                                    <option value={item.name}>{item.name}</option>

                                ))
                            }
                        </select>
                    </div>
                }
                <button className='btn__blue' onClick={handleBilling}>Submit</button>
                {
                    response.success && <p className='success' style={{ paddingTop: "15px" }}>Thanks for filling out our form!</p>
                }
            </form>
        </div>
    )
}
