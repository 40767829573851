import { useState } from "react"

import { auth } from '../firebase.config'
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom"

export const useLogin = () => {
    const [error, setError] = useState("")
    const { dispatch } = useAuthContext()
    const navigate = useNavigate("")

    const Login = (email, password) => {
        setError(null)
        signInWithEmailAndPassword(auth, email, password)
            .then((res) => {
                dispatch({ type: "LOGIN", payload: res.user })
                navigate("/admin/form-list")
            })
            .catch(err => {
                setError(err.message)
            })
    }

    return { error, Login }
}