import React from 'react'
import DashboardFormAdd from '../../../components/organisms/DashboardFormAdd/DashboardFormAdd'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'
import { useCollection } from '../../../hooks/useCollection'

import classes from "./DiagonosticsOrder.module.css"
import DiagonosticsOrderedTable from './DiagonosticsOrderedTable'

export default function DiagonosticsOrder() {
    const {document: ordered} = useCollection("order__diagonostics")
    return (
        <div>
            <div className={classes.telemedicine}>
                <DashboardSidebar />

                <div className={classes.telemedicine__form}>
                    <div className={classes.telemedicine__table}>
                        <DiagonosticsOrderedTable data={ordered} />
                    </div>
                </div>
            </div>
        </div>
    )
}
