import React from 'react'

// import syling
import classes from "./About.module.css"

import { AboutHealthPort, PartnerShip } from './components'
import AboutMission from './components/AboutMission/AboutMission'

export default function About() {
    return (
        <div className='section-pd'>
            <div className='container'>
                <AboutHealthPort />
                <AboutMission />
                <PartnerShip />
            </div>
        </div>
    )
}
