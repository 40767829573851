import React from 'react'
import {Link} from "react-router-dom"

import Button from '../../../../components/molecules/Button/Button'

import classes from "./Hospital.module.css"


export default function Hospital({ hospital }) {
    const {hospitalName, hospitalTags,hospitalImg} = hospital?.data
    return (
        <div className={`${classes.hospital__card}`}>
            <img className={classes.hospital__img} style={{width: "100%", height: "150px"}} src={hospitalImg} alt={hospitalName} />
            <h6 className={classes.hospital__name}>{hospitalName}</h6>

            <div className={`${classes.hospital__tags}`}>
                {
                    hospitalTags.map(tag => (
                        <span className={classes.tag}>{tag}</span>
                    ))
                }
            </div>
            <Link to={`/hospitals/${hospital.id}`}><Button >View More</Button></Link>
        </div>
    )
}
