import React from 'react'
import { deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../../firebase.config'

import editIcon from '../../../assets/images/edit.png'
import deleteIcon from '../../../assets/images/delete.png'

export default function DashboardHospitalTable({ data }) {
    const handleDelete = async (id) => {
        const ref = doc(db, "hospitals", id)
        await deleteDoc(ref)
    }

    return (
        <div className='tableScrool'>
            <table>
                <thead className="table__heading">
                    <tr>
                        <th>NO</th>
                        <th>Hospital Name</th>
                        <th>Hospital Description</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody className="table__body">

                    {
                        data && data.map((item, i) => (
                            <tr key={item.id}>
                                <td>{i + 1}</td>
                                <td>{item.data.hospitalName}</td>
                                <td>{item.data.hospitalDescription.substring(0, 90)}</td>
                                <td>
                                    <div>
                                        <img style={{ width: "30px", height: "30px" }} src={editIcon} alt="edit icon" />
                                        <img style={{ width: "30px", height: "30px" }} src={deleteIcon} onClick={() => handleDelete(item.id)} alt="delete icon" />
                                    </div>

                                    {/* <select className={statusClass(item.status)} >
                                    <option value="pending">{item.status}</option>
                                    <option value="done">Done</option>
                                </select> */}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
