import React, { useRef, useState } from 'react'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../../firebase.config'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/molecules/Button/Button'
import { useStorage } from '../../../hooks/useStorage'

export default function HospitalsFormAdd({ btnTitle }) {
    const navigate = useNavigate()
    const [hospitalName, setHospitalName] = useState("")
    const [country, setCountry] = useState("")
    const [location, setLocation] = useState("")
    const [tag, setTag] = useState("")
    const [tags, setTags] = useState("")
    const [description, setDescription] = useState("")

    const [file, setFile] = useState("");
    const [err, setErr] = useState(null);
    const types = ['image/png', 'image/jpeg'];

    const { error, url, progress } = useStorage(file)


    const currentTagRef = useRef(null)

    const hospitalCollectionRef = collection(db, "hospitals")

    const handleTag = e => {
        e.preventDefault()

        let singleTag = tag.trim()
        if (singleTag && !tags.includes(singleTag)) {
            setTags(prevTag => [...prevTag, singleTag])
        }

        setTag("")
        currentTagRef.current.focus()
    }


    const handleChange = e => {
        let selected = e.target.files[0];

        if (selected && types.includes(selected.type)) {
            setFile(selected);
            setErr('');
        } else {
            setFile(null);
            setErr('Please select an image file (png or jpg)');
        }
    }

    const handleSubmit = e => {
        e.preventDefault()

        const data = {
            hospitalName,
            hospitalTags: tags,
            hospitalDescription: description,
            hospitalImg: url,
            country: country,
            location: location
        }

        if (data.hospitalName && data.hospitalTags && data.hospitalDescription && data.hospitalImg && data.location) {
            const createDiagonstics = async () => {
                await addDoc(hospitalCollectionRef, { data })
            }
            createDiagonstics()
            navigate('/admin/hospital-list')
        }
    }
    return (
        <div className="dashboardForm">
            <h2 className="dashboardTitle">{btnTitle}</h2>
            <form onSubmit={handleSubmit} className="form">
                <div className="contact_form grid__container grid__coloumn_6">
                    <div className="input">
                        <input type='text' placeholder='Hospital Name' onChange={e => setHospitalName(e.target.value)} />
                    </div>
                    <div className="filter">
                        <label className='filter__label' for="cars">Add Country</label>
                        <select className="selected" name="hospitals" onChange={e => setCountry(e.target.value)} id="hospitals">
                            <option value="">Select A Country</option>
                            <option value="India">India</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Singapore">Singapore</option>
                            <option value="UAE">UAE</option>
                            <option value="Turkey">Turkey</option>
                        </select>
                    </div>
                    {
                        country === "Thailand" && <div className="filter">
                            <label className='filter__label' for="cars">Add Location</label>
                            <select className="selected" name="hospitals" onChange={e => setLocation(e.target.value)} id="hospitals">
                                <option value="">Select A location</option>
                                <option value="Bangkok">Bangkok</option>
                                <option value="Nonthaburi">Nonthaburi</option>
                                <option value="Nakhon Ratchasima">Nakhon Ratchasima</option>
                                <option value="Chiang Mai">Chiang Mai</option>
                            </select>
                        </div>
                    }
                    {country === "India" && <div className="filter">
                        <label className='filter__label' for="cars">Add Country</label>
                        <select className="selected" name="hospitals" onChange={e => setLocation(e.target.value)} id="hospitals">
                            <option value="">Select A location</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Rajastan">Rajastan</option>
                            <option value="Chennai">Chennai</option>
                        </select>
                    </div>}
                    {country === "Singapore" && <div className="filter">
                        <label className='filter__label' for="cars">Add Country</label>
                        <select className="selected" name="hospitals" onChange={e => setLocation(e.target.value)} id="hospitals">
                            <option value="">Select A location</option>
                            <option value="Hougang">Hougang</option>
                            <option value="Tampines">Tampines</option>
                            <option value="Pasir Ris">Pasir Ris</option>
                        </select>
                    </div>}
                    {country === "UAE" && <div className="filter">
                        <label className='filter__label' for="cars">Add Country</label>
                        <select className="selected" name="hospitals" onChange={e => setLocation(e.target.value)} id="hospitals">
                            <option value="">Select A location</option>
                            <option value="Dubai">Dubai</option>
                            <option value="Abu Dhabi">Abu Dhabi</option>
                            <option value="Sarjah">Sarjah</option>
                        </select>
                    </div>}
                    {country === "Turkey" && <div className="filter">
                        <label className='filter__label' for="cars">Add Country</label>
                        <select className="selected" name="hospitals" onChange={e => setLocation(e.target.value)} id="hospitals">
                            <option value="">Select A location</option>
                            <option value="Istanbul">Istanbul</option>
                            <option value="Izmir">Izmir</option>
                            <option value="Antalya">Antalya</option>
                        </select>
                    </div>}
                    <div className="input">
                        <input type='text' placeholder='Add Tag' onChange={e => setTag(e.target.value)} ref={currentTagRef} value={tag} />
                        <button onClick={handleTag}>add Tags</button>
                    </div>
                </div>
                <input type="file" onChange={handleChange} />
                <textarea className="textarea" placeholder="Hospital Description" onChange={e => setDescription(e.target.value)} />
                <Button className="flex">{btnTitle}</Button>
            </form>
        </div>
    )
}
