import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import logo from "../../src/assets/images/healthport-logo-white.png"
import facebook from "../../src/assets/images/facebookIcon.png"
import linkedin from "../../src/assets/images/linkedinIcon.png"
import tweeter from "../../src/assets/images/twitterIcon.png"
// import style
import classes from "./Footer.module.css"

export default function Footer() {
    const date = new Date().getFullYear();
    return (
        <footer className={`${classes.footer}`}>
            <div className={`${classes.footer_grid} container grid__container grid__coloumn_2Something pd_4`}>
                <div className="text__center_sm">
                    <img src={logo} className={`${classes.footer__logo}`} alt="healthport logo" />
                </div>
                <div className="text__center_sm">
                    <ul className={classes.footer_lists}>
                        <Link to="/about-us" style={{ color: "#fff", textDecoration: "none" }}><li className={classes.footer_list}>About</li></Link>
                        <li className={classes.footer_list}>Team</li>
                        <li className={classes.footer_list}>Career</li>
                        <li className={classes.footer_list}>Media</li>
                    </ul>
                </div>
                <div className="text__center_sm">
                    <ul className={classes.footer_lists}>
                        <li className={classes.footer_list}>Help</li>
                        <li className={classes.footer_list}>FAQ</li>
                        <li className={classes.footer_list}>Terms and Conditions</li>
                        <li className={classes.footer_list}>Privacy Policy</li>
                    </ul>
                </div>
                <div className="text__center_sm">
                    <ul className={classes.footer_lists}>
                        <Link to="/partner" style={{ color: "#fff", textDecoration: "none" }}><li className={`${classes.footer_list} ${classes.partner}`}>Become a Partner</li></Link>
                        <Link to="/contact-us" style={{ color: "#fff", textDecoration: "none", }}><li className={classes.footer_list}>Contact us</li></Link>
                    </ul>

                </div>
                <div className="text__center_sm">
                    <h2 className={classes.Details}>Address</h2>
                    <div className="flex margin_bottom_largeX">
                        {/* <p className={classes.footer__location_details}>2<sup>nd</sup> Floor, InnStar Tree House, House 50, Road 23, Banani, Dhaka-1213</p> */}
                        <p className={classes.footer__location_details}>Level 3, House 19, Road 27, Banani, Dhaka-1213</p>
                    </div>
                    <div className="mb_2">
                        <p className={classes.footer__location_details}>Phone: </p>
                        <p className={classes.footer__location_details}>+880 1312 872136</p>
                    </div>
                </div>
            </div>

            <div className={`${classes.footer__social} container text_center`}>
                <div className='flex justify_center'>
                    <a href='https://www.facebook.com/healthportbangladesh/' target="_blank" ><img src={facebook} alt="facebook" /></a>
                    <a href='https://www.linkedin.com/company/healthportbangladesh/?originalSubdomain=bd' target="_blank" ><img src={linkedin} alt="linkedin" /></a>

                </div>
                <p className={classes.footer__greet}>{date} @ all right reserved by Healthport | Designed and Developed by <a href='https://www.ongshak.com/' style={{ cursor: "pointer", fontWeight: "700", color: "#fff" }} target="_blank">Ongshak</a></p>
            </div>
        </footer>
    )
}
