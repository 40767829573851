import React from 'react'
import classes from './ContactTitle.module.css'

export default function ContactTitle() {
    return (
        <div className={`${classes.contact_headline} text_center`}>
            <span>Contact Us</span>
        </div>
    )
}
