import React from 'react'
import DashboardForm from '../../../components/organisms/DashboardForm/DashboardForm'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'


import classes from "./DashboardTreatmentsAdd.module.css"

export default function DashboardTreatmentsAdd() {
    return (
        <div>
            
            <div className={classes.dashboard__form}>
                <DashboardSidebar />

                <div className={classes.dashbard__form_add}>
                    <DashboardForm btnTitle="Add Treatments" />
                </div>
            </div>
        </div>
    )
}
