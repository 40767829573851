import React from 'react'
import PartnerForm from '../../components/organisms/PartnerForm/PartnerForm'
import classes from "./BecameAPartner.module.css"

export default function BecameAPartner() {
    return (
        <div className={classes.partner__page}>
            <h2 style={{ fontSize: "22px" }}>Became a Partner</h2>
            <PartnerForm />
        </div>
    )
}
