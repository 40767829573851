import React, {useState} from 'react'
import DashboardFormAdd from '../../../components/organisms/DashboardFormAdd/DashboardFormAdd'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'


import classes from "./DashboardHospital.module.css"
import DashboardHospitalTable from './DashboardHospitalTable'
import { useCollection } from '../../../hooks/useCollection'

export default function DashboardHospital() {
    let [search, setSearch] = useState("")
    const {document: hospitalData} = useCollection("hospitals")

    const searchedHospital = hospitalData.find(data => (data?.data?.hospitalName.includes(search)))

    return (
        <div>
            
            <div className={classes.telemedicine}>
                <DashboardSidebar />

                <div className={classes.telemedicine__form}>
                    <DashboardFormAdd path="/hospital-list/add" title="Hospital Form List" setSearch ={setSearch} />
                    <div className={classes.telemedicine__table}>
                        <DashboardHospitalTable data ={ hospitalData} />
                    </div>
                </div>
            </div>
        </div>
    )
}

