import React, {useState} from 'react'
import DashboardSidebar from '../../../components/organisms/DashboardSidebar/DashboardSidebar'
import { useCollection } from '../../../hooks/useCollection'

import classes from "./DashboardDiagonostics.module.css"
import DiagonosticsTable from './DiagonosticsTable'
import DiagonsticsFormAdd from './DiagonsticsFormAdd'

export default function DashboardDiagonostics() {

    let { document: diagonosticsData } = useCollection("diagnostics")

    let [category, setCategory] = useState("")
    let [search, setSearch] = useState("")

    let filterredData = diagonosticsData.filter(doc => doc.Category.toLowerCase() === category.toLowerCase())
    let searchByName = diagonosticsData.find(data => (data.Name.includes(search)))
    
    let showingData = filterredData.length > 0 ? filterredData : diagonosticsData

    return (
        <div>
            <div className={classes.diagonostics}>
                <DashboardSidebar />

                <div className={classes.diagonostics__form}>
                    <DiagonsticsFormAdd path="/diagonostics/add" title="Diagonostics Form List" collectionName="diagonstics" setSearch={setSearch} setCategory={setCategory} />
                    <div className={classes.diagonostics__table}>
                        <DiagonosticsTable data={showingData} />
                    </div>
                </div>
            </div>
        </div>
    )
}
