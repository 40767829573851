import React, { useState } from 'react'
import classes from './DashboardForm.module.css'
import Button from '../../molecules/Button/Button'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../../firebase.config'
import { useNavigate } from 'react-router-dom'

export default function DashboardForm({ btnTitle }) {
    const navigate = useNavigate()
    const [serialNumber, setSerialNumber] = useState(0)
    const [title, setTitle] = useState("")
    const [price, setPrice] = useState(0)

    const diagonsticsCollectionRef = collection(db, "diagonstics")

    const handleSubmit = e => {
        e.preventDefault()

        const data = {
            Serial: serialNumber,
            ExamName: title,
            price: Number(price)
        }

        const createDiagonstics = async () => {
            await addDoc(diagonsticsCollectionRef, { data })
        }

        createDiagonstics()
        navigate('/admin/diagonostics')
    }

    return (
        <div className={classes.dashboardForm}>
            <h2 className={classes.dashboardTitle}>{btnTitle}</h2>
            <form onSubmit={handleSubmit} className={classes.form}>
                <div className={`${classes.contact_form} grid__container grid__coloumn_6`}>
                    <div className={`${classes.input}`}>
                        <input type='number' placeholder='Serial Number' onChange={e => setSerialNumber(e.target.value)} />
                    </div>
                    <div className={`${classes.input}`}>
                        <input type='text' placeholder='Exam Name' onChange={e => setTitle(e.target.value)} />
                    </div>
                </div>
                <div className={`${classes.contact_form} grid__container grid__coloumn_6`}>
                    <div className={`${classes.input}`}>
                        <input type='number' placeholder='Price' onChange={e => setPrice(e.target.value)} />
                    </div>

                    {/* <div className={`${classes.input}`}>
                        <input type='text' placeholder="Doctor's number"/>
                    </div> */}
                </div>
                {/* <textarea className={classes.textarea} placeholder="Your Message" name="message" /> */}
                <Button className="flex">{btnTitle}</Button>
            </form>
        </div>
    )
}
