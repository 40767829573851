import { useContext } from "react"
import { VaccineContext } from "../context/useVaccineContext"

export const useVaccineContext = () => {
    const context = useContext(VaccineContext)

    if(!context) {
        throw new Error("useVaccineContext must be inside an vaccineProvider")
    }

    return context
}