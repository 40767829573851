import {useState } from 'react'
import { useSignUp } from '../../hooks/useSignUp'

import classes from "./SignUp.module.css"


export default function SignUp() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const {error, singUp} = useSignUp()

    const reset = () => {
        setEmail('')
        setPassword('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        singUp(email, password)
        reset()
    }

    return (
        <form onSubmit={handleSubmit} className={classes.signup_form} >
            <h2>Signup</h2>
            <label>
                <span>email:</span>
                <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
            </label>
            <label>
                <span>password:</span>
                <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
            </label>

            {
                error && <p style={{color: "red"}}>{error}</p>
            }
            <button className='btn__blue'>Sign Up</button>

        </form>
    )
}
