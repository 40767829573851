import React from 'react'
import editIcon from '../../../../assets/images/edit.png'
import deleteIcon from '../../../../assets/images/delete.png'
import { deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../../../firebase.config'

export default function VaccineTable({ data }) {
    const handleDelete = async (id) => {
        const ref = doc(db, "all__list", id)
        await deleteDoc(ref)
    }
    return (
        <div className='tableScrool'>
            <table>
                <thead className="table__heading">
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>age</th>
                        <th>Gender</th>
                        <th>Address</th>
                        <th>Living Area</th>
                        <th>Body Type</th>
                        <th>Vaccine Name</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody className="table__body">
                    {
                        data && data.map((item, i) => (
                            <tr key={item.id}>
                                <td>{item?.doc?.name}</td>
                                <td>{item?.doc?.email}</td>
                                <td>{item?.doc?.number}</td>
                                <td>{item?.doc?.age}</td>
                                <td>{item?.doc?.gender}</td>
                                <td>{item?.doc?.address}</td>
                                <td>{item?.doc?.livingArea}</td>
                                <td>{item?.doc?.selectedBody}</td>
                                <td>{item?.doc?.selectedSymptoms}</td>
                                <td>
                                    <div>
                                        <img style={{ width: "30px", height: "30px" }} src={editIcon} alt="wrong icon" />
                                        <img style={{ width: "30px", height: "30px" }} onClick={() => handleDelete(item.id)} src={deleteIcon} alt="wrong icon" />
                                    </div>

                                    {/* <select className={statusClass(item.status)} >
                                    <option value="pending">{item.status}</option>
                                    <option value="done">Done</option>
                                </select> */}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
