import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import { db } from "../../firebase.config";
import { collection, getDocs } from "firebase/firestore";
import TreatmentsDetails from "./components/TreatmentsDetails";

import classes from "./Treatments.module.css"

export default function Treatments() {
    let [adultBodySegment, setAdultBodySegment] = useState([])
    let [womenBodySegment, setWomenBodySegment] = useState([])
    let [babyBodySegment, setBabyBodySegment] = useState([])
    let [category, setCategory] = useState("head of adult")
    let [organs, setOrgans] = useState([])
    let [selectOrgans, setSelectOrgans] = useState(category)
    let [symptoms, setSymptoms] = useState([])


    let userOrganCollectionRef = collection(db, "organs")
    let userSymptomCollectionRef = collection(db, "symptom")
    let adultBodyCollectionRef = collection(db, "bodyParts")
    let womenBodyCollectionRef = collection(db, "bodyPartWomen")
    let babyBodyCollectionRef = collection(db, "bodyPartBaby")

    useEffect(() => {
        const getOrgans = async () => {
            const data = await getDocs(userOrganCollectionRef)
            setOrgans(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }

        const getSymptoms = async () => {
            const data = await getDocs(userSymptomCollectionRef)
            setSymptoms(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }

        const getAdultBodysegment = async () => {
            const data = await getDocs(adultBodyCollectionRef)
            setAdultBodySegment(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }

        const getWomenBodysegment = async () => {
            const data = await getDocs(womenBodyCollectionRef)
            setWomenBodySegment(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }
        const getBabyBodysegment = async () => {
            const data = await getDocs(babyBodyCollectionRef)
            setBabyBodySegment(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }

        getOrgans()
        getSymptoms()
        getAdultBodysegment()
        getWomenBodysegment()
        getBabyBodysegment()
    }, [])
    return (
        <div className={classes.treatment__page}>
            <TreatmentsDetails category={category} setCategory={setCategory} adultBodySegment={adultBodySegment} womenBodySegment={womenBodySegment} babyBodySegment={babyBodySegment} selectOrgans={selectOrgans} setSelectOrgans={setSelectOrgans} organs={organs} setOrgans={setOrgans} symptoms={symptoms}  />
        </div>
    )
}
