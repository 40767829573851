import React from 'react'
import icon  from "../../../../assets/images/healthport_plus.PNG"
import classes from "./AboutMission.module.css"

export default function AboutMission() {
    return (
        <div>
            <h2 className={classes.mission__title}>Our Mission</h2>
            <div className={`${classes.card_container} grid__container grid__coloumn_6`}>
                <div className={classes.mission}>
                    <img src={icon} alt="icon" />
                    <h2>Ensure "Client First" Approach - Your Health Your Say</h2>
                </div>
                <div className={classes.mission}>
                    <img src={icon} alt="icon" />
                    <h2>Secure Patient Safety And Comfort</h2>
                </div>
                <div className={classes.mission}>
                    <img src={icon} alt="icon" />
                    <h2>Provide Best Practice Health Care Services</h2>
                </div>
                <div className={classes.mission}>
                    <img src={icon} alt="icon" />
                    <h2>Assist In Planning,Implementing, Monitoring And Following Up Tour Packages</h2>
                </div>
                <div className={classes.mission}>
                    <img src={icon} alt="icon" />
                    <h2>Enable Patient To Take Informed Decision Based On Evidence And TrustWorthiness</h2>
                </div>
                <div className={classes.mission}>
                    <img src={icon} alt="icon" />
                    <h2>Strictly Adhere Laws And Regulations Including Ethical Codes</h2>
                </div>
            </div>
        </div>
    )
}
