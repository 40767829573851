import { createContext, useEffect, useReducer } from "react";
import { auth } from "../firebase.config";
import { onAuthStateChanged } from "firebase/auth"

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch(action.type) {
        case "LOGIN": 
            return {...state, user: action.payload}
        case "LOGOUT": 
            return {...state, user: null}
        case "AUTH__IS__READY": 
            return {...state, user: action.payload, authIsReady: true}
        default: 
            return state
    }
}

export const AuthContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false
    })

    useEffect(() => {
        const unSub = onAuthStateChanged(auth, (user) => {
            dispatch({type: "AUTH__IS__READY", payload: user})
            unSub()
        })
    }, [])

    return (
        <AuthContext.Provider value={{...state, dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}